.availability-bar-display {
  display: flex; /* Keep the flex display */
  width: 1046px; /* Update width */
  height: 40px; /* Update height */
  padding: 6px 18px; /* Update padding */
  justify-content: space-between; /* Keep justify content */
  align-items: center; /* Keep align items */
  flex-shrink: 0; /* Add flex-shrink */
  border-radius: 0px 0px 26px 26px; /* Update border-radius */
  border-top: 1px solid var(--Background---SEOA, #F2EEF3); /* Update border */
  background: var(--Blanc, #FFF); /* Keep the background color */
  margin-bottom: 7px; /* Keep margin-bottom */
  margin-top: -5px;
  
  font-family: 'Poppins'; /* Keep font family */
  font-size: 12px; /* Keep font size */
  font-weight: 600; /* Keep font weight */
}

.availability-bar-display .s_colum {
  white-space: nowrap; /* Keep white-space rule */
}


  
  .cart-button button:hover {
    background-color: #e27157; /* Darker on hover */
  }
  .options-container {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    padding: 3px 10px; /* Padding around the container */
    border-radius: 8px; /* Rounded corners */
    background: var(--Background---DISPLAY, #EEF3F2); /* Background color */
    gap: 27px; /* Space between each option */
    
  }
  .cursor-pointer {
    cursor: pointer;
}
  
.selectedDisplay{
  background: #CFDAD8 !important;
}


.exclusivity-select-display {
  display: flex;
  padding: 10px 20px;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 26px;
  background: var(--Soft-gray, #F6F6F6);
}
.config-popup-p{
  padding-top: 10px;
}
.box-display{margin: 0 auto;}
.box-display .config-popup-box {
  width: 190px;
  padding: 10px 20px;
  margin: 0 auto;
}
.config-display .config-popup-date{padding: 10px;}
.categorieDisp{
  width: 255px;
    margin-left: 62px;
}

.ctrDisp{
  margin-left: 25px;
}

.prixDisp{
  margin-left: 80px;
}


.config-popup-contentDisp {
  position: relative;
  display: flex
;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 848px;
  padding: 30px 20px;
  border-radius: 26px;
  border: 1px solid var(--Soft-gray, #F6F6F6);
  background: #F7F7F7;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 141px;
  margin-top: 130px;
}
