

/* Mobile styles for screens 425px or smaller */
.mobile_block{
    display: none;
}
@media (max-width: 425px) {
    .intr_block {         
        display: none !important;       
    }

    .mobile_block{
        display: flex;
        width: 100%;
        height: 844px;
        padding: 50px 23px;
        flex-direction: column;
        align-items: center;
        background: var(--Violet-doux, #8D658A);
    }
    .mobile_block .branding img {
        width: 150px;
        margin-bottom: 40px;
    }
    .mobile_block .img-left {        
        width: 260px;
        height: 255px;        
        background: url('../../../public/assets/images/login/image_login.png') 50% / cover no-repeat;
        margin-bottom: 40px;
        margin-left: 52px;
    }
    .mobile_block .titre{
        width: 344px;
        color: var(--Blanc, #FFF);
        text-align: center;        
        font-family: 'Poppins' !important;
        font-size: 22px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;  
        margin-bottom: 30px;         
    }
    .mobile_block .orange_text{
        color: var(--Pche-vive, #FF8366);          
    }

    .mobile_block .button-link{
        color: inherit;
        text-decoration: unset;
    }

    .mobile_block .div-start-button {
        display: flex;
        height: 40.05px;
        width:344px;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 26px;
        background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
                
        font-family: 'Poppins';
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
    }

    .mobile_block .div-start-button:hover {
        background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    }

    .mobile_block .under_text{
        color: var(--Blanc, #FFF);
        text-align: center;        
        font-family: 'Poppins';
        font-size: 14px !important;  
        margin-top: 30px;                       
    }
    .mobile_block .text_se{             
        font-family: 'Poppins';
        font-size: 14px; 
        font-style: normal;
        font-weight: 700 !important; 
        line-height: normal;
        font-weight: bold;                            
    }
    .mobile_block .mobile_block {
        color: var(--Blanc, #FFF);
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 700 !important; 
        line-height: normal;
        font-weight: bold;
    }

    .carrousel-next-mobile{
        margin-bottom: 30px;        
        display: flex;
        align-items: center;
        gap: 12px;
        
    }
    .carrousel-next-div-mobile{
        width: 193px;
        height: 14px;
        flex-shrink: 0;
    }
  
    .mobile_block .seao_mobile{
        display: flex;
        justify-content: center;
        padding: 5px 20px;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
        margin-top: 20px;
    }
    .mobile_block .h2-button{
        display: inline-flex;        
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        background: var(--Blanc, #FFF);
        color: #8D658A;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 5px 20px;
        align-items: center;        
    }
    .mobile_block .titre_2{
        width: 344px;
        color: var(--Blanc, #FFF);
        text-align: center;        
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;      
    }

    .mobile_block .link_mobile{
        color: inherit;
        text-decoration: unset;
    }


    .image-container-mobile {
        display: flex;
        
        
        /* gap: 16px; */
    }

    .image-1 {
        width: 215.755px;
        height: 341.001px;
        flex-shrink: 0;
        background-image: url('../../../public/assets/images/seoa/img3v2.png');
        background-size: cover;
        background-position: center;
        position: relative; /* Or use `absolute` if necessary */
        top: 0px;
        left: 125px;
        margin-left: -46px;
    }

    .image-2 {
        width: 189px;
        height: 137px;
        flex-shrink: 0;
        background-image: url('../../../public/assets/images/seoa/img2v3.png');
        background-size: cover;
        background-position: center;
        position: relative;
        top: 155px;
        left: -117px;
        margin-left: -46px;
    }


    .image-1-aff {
        width: 202.755px;
        height: 347.001px;
        flex-shrink: 0;
        background-image: url('../../../public/assets/images/seoa/img5v2.png');
        background-size: cover;
        background-position: center;
        position: relative; /* Or use `absolute` if necessary */
        top: -10px;
        left: 97px;
        margin-left: -93px;
    }

    .image-2-aff {
        width: 237px;
        height: 173px;
        flex-shrink: 0;
        background-image: url('../../../public/assets/images/seoa/img4v3.png');
        background-size: cover;
        background-position: center;
        position: relative;
        top: 140px;
        left: -28px;
    }
    .image-1-disp {
        
        width: 290px;
        height: 244px;
        flex-shrink: 0;
        border-radius: 8px;
        background: url('../../../public/assets/images/seoa/img9v3.png') center / contain no-repeat;
        /* box-shadow: 12px 9px 15px -6px rgba(19, 18, 19, 0.18); */
        position: relative;
        top: 148px;
        left: 178px;
        margin-left: -141px;
    }
    

    .image-2-disp {
        width: 198px;
        height: 145px;
        flex-shrink: 0;
        background-image: url('../../../public/assets/images/seoa/img8v3.png');
        background-size: cover;
        background-position: center;
        position: relative;
        top: 79px;
        left: -132px;
    }

    .block_info{
        height: 590px;
    }  
    
    .table-category-mobile{
        /* width: 8%; */
        width: 420px;
        margin-left: 2%;
        text-wrap: nowrap;
    }
    
}

