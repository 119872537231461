* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;    
}

body {
    background: var(--Background, rgba(176, 139, 187, 0.10));
    font-family: 'Poppins' !important;
    font-style: normal;
}

.contanaire {
    display: flex;
    max-width : fit-content;
    padding-left: 137px;
    padding-right: 137px;
    justify-content: center;
    align-items: center;

    width: 1412px;
    height: 100vh;
    gap: 0px;
    opacity: 1;
  
}

.contanairetwo {
    display: flex;
    width: 100%;
    max-width : fit-content;
    padding-left: 74px;
    padding-right: 69px;
    justify-content: center;
    align-items: center;
    /* width: 1412px; */
    height: 100vh;
    gap: 0px;
    opacity: 1;
}

.left-section {    
    background: var(--Violet-doux, #8D658A);        
    flex: 1;    
    display: inline-flex;   
    justify-content: space-between;    
    border-radius: 26px 0px 0px 26px;    
    padding: 34px 57px 62px 41px;
    flex-direction: column;
    align-items: flex-start;
    gap: 74px;      
    width: 586px;        
    height: 746px;        
    padding: 34px 57px 373px 41px;
    gap: 10px;    
    opacity: 1;

}
.left-section #ads_svg{
    margin-left: 70px;
}

.left-section .branding {
    display: flex;
    align-items: center;
    gap: 30px;            
}

.left-section .branding p{
    color: var(--Blanc, #FFF);    
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;    
    line-height: normal;
}
.left-section .branding .line{
    width: 1px;
    height: 48px;
    background: #FFF;
}

.left-section .branding img {
    width: 150px;
    margin-bottom: 20px;
}

.left-section .branding p {
    font-size: 16px;
}

.left-section .slogan h2 {
    color: var(--Blanc, #FFF);
    font-family: 'Poppins';
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.left-section .slogan span {
    color: var(--Pche-vive, #FF8366);
}
.left-section .section_img{
    width: 100%;
    display: flex;
    justify-content: center;
}
.left-section .section_img img{
    width: 313px;
    height: 306px;
    flex-shrink: 0;
}
.right-section {
 /*    background-color: white;
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 10px 10px 0;
    max-width: 500px; */

    display: flex;    
    width: 852px;
    height: 746px;
    padding: 50px 100px;
    margin-left: -25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 27px;
    flex-shrink: 0;
    border-radius: 26px;
    background: #FFF;
}

.form-header p{
    color: var(--Violet-doux, #8D658A);
    text-align: right;    
    font-family: 'Poppins';
    font-size: 12px;    
    font-style: normal;    
    line-height: normal;
}
.form-header .lien{
    color: var(--Violet-doux, #8D658A);                
    font-weight: 900 !important;
    line-height: normal;
    text-decoration: none;
}

.form-header{
    width: 100%;        
}
.form-header p button{
    all:unset;
    cursor: pointer;
}

.form-header h2 {        
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;    
}

.input-group label {       
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--Gray, #4C4C4C);
    /* Text/Accent */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.input-group input {        
    border: none;       
    display: flex;
    height: 38px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 26px;
    background: #F7F7F7;
    height: 38px;
    width: 100%;

    color: var(--Violet-doux, #8D658A);
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

input{    
    outline: none; /* Ensure no outline is shown on focus */
    border: none; /* Ensure no border is shown on focus */        
}

.input-group input.input-error {
    border: 1px solid var(--Pche-vive, #FF8366); /* Red border for error */
    background: #F7F7F7;
}

.checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    margin-top: 10px;
}

.checkbox-group input {    
    margin-right: 10px;    
}

.checkbox-group label {
    color: var(--Gray, #4C4C4C);    
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.checkbox-group .link_check {    
    text-decoration: none;
    color: var(--Violet-doux, #8D658A);    
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-decoration-line: underline;
}

.submit-btn {       
    border: none;  
    display: flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Disable-gradient, linear-gradient(90deg, #9D9D9C -27.35%, #E5E1DB 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: #FFFFFF;
}
.submit-btnNotDisabled {       
    border: none;  
    display: flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: #FFFFFF;
}
.section_submit{
    width: 100%;
    display: flex;
    justify-content: center;    
}

#block_login{
    width: 100%;   
    display: contents; 
}
#block_sginup{
    width: 60%;
    display: none;    
}
@media (max-width: 1024px) {
    #block_sginup{
        width: 100% !important;            
    }
}
input::placeholder {
    color: var(--Strong-gray, #9D9D9C) !important;        
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}
.form-header-login{
    width: 100%;        
}
.form-header-login p button{
    all:unset;
    cursor: pointer;
}

.form-header-login h2 {        
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;    
}

.section_submit_login{
    width: 100%;
    display: flex;
    justify-content: center;    
}
.footer_text{
    color: var(--Violet-doux, #8D658A);    
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;    
    line-height: normal;    
}
.footer_text .lien{
    color: var(--Violet-doux, #8D658A);                
    font-weight: 900 !important;
    line-height: normal;
    text-decoration: none;
}

.lienSeconnecte{
    color: var(--Violet-doux, #8D658A);                
    font-weight: 200 !important;
    line-height: normal;
    text-decoration: none;
}

.footer_text P button{    
    all:unset;
    cursor: pointer;    
}

.span_error{
    align-self: stretch;
    color: #FF7E7E;

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.reset-password-title {
    width: 517px;
    color: var(--Pche-vive, #FF8366);
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}


/* Styles for the reset password block */
#block_forgot_password {
    width: 517px;
    margin: 0 auto;
    display: none; /* Initially hidden, adjust visibility with JavaScript */
    white-space: nowrap;
}

/* Title styling */


/* Label and input styling */
#block_forgot_password  label {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-bottom: 8px;
}



#block_forgot_password  input[type="email"]:focus {
    border-color: #FF8366;
}

/* Error message styling */
.error {
    color: #FF6363;
    font-size: 14px;
    margin-top: 5px;
    display: block;
}

/* Submit button styling */
.section_submit_reset {
    display: flex;
    justify-content: center;
    align-items: center; /* Ensure vertical centering */
    margin-top: 20px;
    width: 100%; /* Set to 100% if the parent container is correctly centered */
    text-align: center; /* Helpful for centering inline elements */
}

.section_submit_reset .submit-btn {
    padding: 10px 20px;
    background-color: #FF8366;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 auto; /* Centers the button within the flex container */
}


.section_submit_reset .submit-btn:hover {
    background-color: #e26f58;
}

.section_submit_reset .submit-btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}


/* .reset-password-label {
    display: flex;
    font-size: 13px;
    width: 517px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    white-space: nowrap;
} */

/* .reset-password-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto; 
    padding: 0 15px;
} */


.reset-password-label {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: var(--Pche-vive, #FF8366);
}

.ou-text {
    margin: 0 8px; /* Adjust for more or less space */
    
    vertical-align: middle;
}

.reset-password-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Ensures responsive layout */
    max-width: 517px;
    margin: 0 auto;
    /* align-items: flex-start; */
}

.reset-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
}


.footer_textTwo{
    color: var(--Violet-doux, #8D658A);    
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;    
    line-height: normal;    
    justify-content: center;
    width: 150% !important;
    text-align: center;
    

}
.marginLeftFtr{
    margin-left: -113px;
}
.footer_textTwo .lien{
    color: var(--Violet-doux, #8D658A);                
    font-weight: 900 !important;
    line-height: normal;
    text-decoration: none;
}

.footer_textTwo P button{    
    all:unset;
    cursor: pointer;    
}

.lien, button {
    cursor: pointer;
}




.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8); /* Light background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears above all other content */
}

.loading-spinner {
    width: 100px; /* Adjust size as needed */
    height: 100px;
}

.required-symbol {
    /* color: red; */
    margin-left: 1px;
}


.required-fields-notice {
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins', sans-serif;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}





/* buttonStyles.css */
.custom-button {
  display: inline-flex;
  height: 40px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 26px;
  background: linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%);
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.09);
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.custom-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.loading-spinner {
  animation: spin 1s linear infinite;
  width: 24px;
  height: 24px;
}

.icon {
  display: flex;
  align-items: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



