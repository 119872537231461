.affMobile{
    display: none ; 
}

@media (max-width: 425px) {
    .affWeb {
        display: none !important; 
      }

    .affMobile {
        /* display: flex; */
        display: inline-block;
        width: 373px; 
        /* width: 172%; */
        /* height: 100vh; */
        padding-left: 10px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        overflow-x: auto;     
        
    }

    .table_scroll {
        overflow-x: auto !important; 
        width: 1300px !important;
    }

    .config-popup-overlay-mobile {
        position: fixed; /* Fixed to the viewport */
        top: 50%; /* Align vertically in the center */
        left: 50%; /* Align horizontally in the center */
        transform: translate(-50%, -50%); /* Shift the popup so it appears centered */
        width: 100%; /* Ensure it covers the entire viewport */
        height: 100%; /* Full height */
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
        backdrop-filter: blur(8px); /* Apply blur effect */
        display: flex; /* Flex container for centering */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
        z-index: 1001;
    }

    .config-popup-content-mobile {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 90%; /* Adjust width for mobile */
        max-width: 400px; /* Prevent it from being too wide */
        padding: 20px;
        border-radius: 16px;
        max-height: 100vh;
    }
    .config-popup-container-date-mobile {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }
    .config-popup-box-date{margin: 0 auto;}
    .config-popup-title {
        color: var(--Pche-vive, #FF8366);
        font-family: Poppins, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex; /* Enable flexbox */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        text-align: center; /* Ensure text itself is centered */
        height: 100%; /* Adjust based on your layout */
    }

    .table-categoryAff{
        /* width: 8%; */
        width: 400px;
        margin-left: 0%;
        text-wrap: nowrap;
    }

    .table-mtsAff{
        width: 11%;
    }
    .table-impressionsAff{
        width: 9%;
    }
    .table-prcAff{
        width: 13%;
    }
    .table-nb-pageAff{
        width: 20%;
    }
}