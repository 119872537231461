.maSelectionMobile{
    display: none ; 
}

@media (max-width: 425px) {
    .maSelectionWeb {
        display: none !important; 
      }


      .maSelectionMobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--Background--SEOA, #F2EEF3);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    
    .header-container {
        display: flex;
        justify-content: space-between; /* Space between the button and logo */
        width: 100%;
        padding: 10px;
        position: absolute; /* Position the container at the top */
        top: 0;
        left: 0;
        right: 0;
    }
    
    .retour-button {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px 8px;
        border-radius: 26px;
        background: linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%);
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        border: none;
        color: white;
        cursor: pointer;
        margin-right: auto;
    }
    
    .retour-button svg {
        flex-shrink: 0; /* Ensures the icon doesn’t shrink */
    }
    .retour-button:hover {
        background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    }
    
    .logo-swipple {
        width: 110px;
        height: 48px;
        margin-left: -173px;
    }

    

    .valider-selection {
        position: absolute; /* Position the div at the bottom */
        right: 20px; /* Place it on the right side */
        bottom: 20px; /* Adjust the distance from the bottom */
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 40.05px;
        padding: 10px 30px;
        border-radius: 26px;
        background: linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%);
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        color: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        text-align: center; /* Ensures text is centered */
        user-select: none; /* Prevent text selection */
    }

    .valider-selection:hover {
        background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    }

    .tabsSelection {
        margin-top: 24px;
        margin-left: 10px; /* Align with your layout */
        z-index: 10; /* Keep it on top of other elements */
        position: relative; /* Ensure proper positioning */
    }
    
    .contentContainer {
        margin-left: 7px;
        display: inline-flex; /* Inline-flex for flexible child layout */
        height: 543.979px;
        padding: 20px 10px; /* Inner padding */
        align-items: center; /* Align items vertically */
        gap: 20px; /* Space between items inside the container */
        flex-shrink: 0; /* Prevent shrinking */
        border-radius: 0px 26px 26px 26px; /* Rounded corners for bottom and right */
        background: #FFF; /* Background color */
        width: calc(104% - 20px);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
        overflow-x: auto; /* Enables horizontal scrolling */
        white-space: nowrap;
    }

    .seao-container-mobile {
        position: sticky;
        flex: 0 0 auto;
        width: 332px;
        height: 461px;
        /* flex-direction: column;
        align-items: center; */
        gap: 7px;
        border-radius: 26px;
        background: var(--Background--SEOA, #F2EEF3);
        margin-top: 0px;
        position: relative;
       
    }
    .scrollBody {
        max-height: 319px;
        overflow-y: auto; /* Enable vertical scrolling */
        overflow-x: hidden; /* Disable horizontal scrolling */
        position: relative;
    }

    .seao-title-mobile {
        color: var(--Blanc, #FFF);
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
    }

    .seao-content-mobile {
        display: flex
    ;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px;
        padding: 0px;
        text-align: center;
        margin-top: 2px;
    }

    .seao-content-wrapper-mobile {
        display: flex;
        height: 221px;
        padding: 9px 29px;
        flex-direction: column;
        align-items: center;
        gap: 11px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 26px;
        border: 2px solid var(--Background--SEOA, #F2EEF3);
        background: var(--Blanc, #FFF);
    }
    .seao-content-wrapper-mobile-disp {
        display: flex;
        /* height: 221px; */
        height: auto;
        padding: 9px 29px;
        flex-direction: column;
        align-items: center;
        gap: 11px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 26px;
        border: 2px solid var(--Background--SEOA, #F2EEF3);
        background: var(--Blanc, #FFF);
    }
    .seao-content-wrapper-mobile-op {
        display: flex;
        height: 116px;
        padding: 9px 29px;
        flex-direction: column;
        align-items: center;
        gap: 11px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 26px;
        border: 2px solid var(--Background--SEOA, #F2EEF3);
        background: var(--Blanc, #FFF);
    }

    .config-selection-container-mobile {
        display: contents;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        flex-wrap: wrap;
    }

    .scrollable-content {
        flex: 1;
        overflow-y: auto; /* Makes the content scrollable */
        padding: 20px;
    }
    .fixed-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background-color: white; /* Ensure it's visible */
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better distinction */
        z-index: 1000;
    }

    .seao-container-aff-mobile {
        display: flex;
        width: 333px;
        height: 509px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: var(--Background---AFFILIATION, #F3F0EE);
        margin-top: 0px;
    }

    .seao-container-display-mobile {
        display: flex;
        width: 333px;
        height: 532px;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        border-radius: 26px;
        background: var(--Background---DISPLAY, #EEF3F2);
        margin-top: 2px;
    }

    .centreP{
        display: flex
;
    justify-content: center;
    }

    .table_scrollSelection_mobile {
        max-height: 800px;
        display: flex; 
        overflow-x: auto; 
        overflow-y: hidden; 
        white-space: nowrap; /* Prevent line breaks for child elements */
        /* padding: 10px 0; */
        gap: 10px;  
    }

    .custom-container-mobile {
        display: inline-flex
;
        height: 46px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 26px;
        background: var(--Blanc, #FFF);
        margin-top: 9px;
        /* margin: 5px; */
        width: 47%;
        margin-left: 90px;
    }

    .delete-campaign-mobile {
        color: var(--Pche-vive, #FF8366);
        font-family: Poppins, sans-serif;
        font-size: 8px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-align: center;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: auto;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        cursor: pointer;
        padding-top: 7px;
    }

    .scrollBodyDisplay {
        max-height: 375px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
    }


    .selection-popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Dim background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; /* Ensure it is on top */
    }
    
    .selection-popup {
        display: flex;
        width: 360px;
        padding: 30px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 26px;
        background: var(--Blanc, #FFF);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1001;
    }


    .titleModal{
        align-self: stretch;
        color: var(--Pche-vive, #FF8366);
        text-align: center;

        /* Mobile/Title/Accent */
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .pModal{
        align-self: stretch;
        color: var(--Gray, #4C4C4C);
        text-align: center;

        /* Mobile/Text/Normal */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .button-containerD {
        display: flex; /* Activates flexbox for the container */
        justify-content: space-between; /* Positions "Annuler" on the left and "Supprimer" on the right */
        align-items: center; /* Aligns buttons vertically in the center */
        width: 100%; /* Ensures the container spans the desired width */
        gap: 10px; /* Adds space between buttons if necessary */
    }
    
    .CancelP {
        display: flex;
        height: 40.05px;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: var(--Soft-gray, #F6F6F6);
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        color: var(--Pche-vive, #FF8366);
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        cursor: pointer;
    }
    
    .deleteP {
        display: flex;
        height: 40.05px;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        color: var(--Background-Blanc, #FFF);
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        cursor: pointer;
    }

    .deleteP:hover {
        background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    }
    
    
    
}