
.form_mobile_block{
    display: none;
}
.confirm_mobile{
    display: none;
}
.new_password_mobile{
    display: none;
}
@media (max-width: 425px) {
    .web_block {         
        display: none !important;       
    }

    body{
        background-color: #FFF !important;
        background: #FFF !important;
    }

    #block_sginup_mobile{
        width: 100%;
        display: none;
    }
    #block_login_mobile{         
        display: contents; 
    }
    #block_forgot_password_mobile{
        display: none;
        width: 100%;
    }    
    .form_mobile_block{           
        display: flex;
        width: 100%;                
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;   
        background: #FFF !important;     
    }    
    .top_mobile{
        display: flex;
        width: 100%;
        height: auto;
        padding: 20px 0px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        flex-shrink: 0;
        border-radius: 0px 0px 93px 86px;
        background: var(--Violet-doux, #8D658A);
    }

    .form_mobile_block .branding img {
        width: 150px;
        margin-bottom: 20px;
    }
    .form_mobile_block .img-left {        
        width: 260px;
        height: 255px;        
        background: url('../../../public/assets/images/login/image_login.png') 50% / cover no-repeat;
        margin-bottom: 40px;
        margin-left: 52px;
    }

    .form_mobile_block .buttom_mobile{
        display: inline-flex;
        height: 234px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        flex-shrink: 0;
        margin-top: 20px;
    }

    .form_mobile_block .form-header-login{
        color: var(--Pche-vive, #FF8366);        
        font-family: 'Poppins';
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 20px;
    }


    .form_mobile_block .submit-btn {       
        border: none;  
        display: flex;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: var(--Disable-gradient, linear-gradient(90deg, #9D9D9C -27.35%, #E5E1DB 77.87%));
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        color: #FFFFFF;    
        height: 40px;                
        align-self: stretch;
        width:344px;
    }

    .form_mobile_block .submit-btn.active {
        background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
        cursor: pointer;
    }
    #block_login_mobile{
        width: 100% !important;
        text-align: center;
    }
    .buttom_mobile .input-group{text-align: left;}

    .form_mobile_block .sginup_mobile{
        display: flex;
        width: 100%;
        height: 844px;
        /* padding: 50px 23px; */
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .form_mobile_block .top_sginup{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;        
    }
    .form_mobile_block .top_titele{
        color: var(--Violet-doux, #8D658A);
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .sginup_mobile .sous_text_conf{
        color: var(--Gray, #4C4C4C);
        text-align: center;        
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 20px;
    }

    .confirme_web{
        display: none !important;
    }

    .confirm_mobile{                                           
        /* display: flex;
        width: 100%;                
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        flex-shrink: 0; */

        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        background: #FFF !important;
    }
    .confirm_mobile .branding img {
        width: 150px;
        margin-bottom: 20px;
    }  
    .confirm_mobile .top_mobile{
        margin-bottom: 60px;  
    } 
    
    .confirm_mobile .img-left {        
        width: 260px;
        height: 255px;        
        background: url('../../../public/assets/images/login/image_login.png') 50% / cover no-repeat;
        margin-bottom: 40px;
        margin-left: 52px;
    }

    .forgot_password_mobile{
        display: flex;
        width: 100%;                      
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        flex-shrink: 0;
    }

    .forgot_password_mobile .footer_textTwo{
        width: 100% !important;
    }
    #new_password_web{
        display: none !important;
    }
    .new_password_mobile{
        display: flex;
        width: 100%;                      
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        flex-shrink: 0;
    }
    .new_password_mobile .branding img {
        width: 150px;
        margin-bottom: 20px;
    } 
    .new_password_mobile .footer_textTwo{
        width: 100% !important;
    }
    .new_password_mobile .submit-btn{
        width: 80% !important;
    }
    .new_password_mobile .form_up{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .new_password_mobile .top_mobile{
        height: auto  !important;
    }

    input::placeholder {
        color: var(--Strong-gray, #9D9D9C) !important;        
        font-family: 'Poppins' !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
    }
    input{
        color: var(--Violet-doux, #8D658A) !important;        
        font-family: 'Poppins' !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important; 
        line-height: normal !important;
    }

    .input-group input, .input-group label {
        font-family: 'Poppins' !important;
        font-size: 14px !important;
        font-style: normal !important; 
        font-weight: 500 !important;
        line-height: normal !important;
        color: var(--Violet-doux, #8D658A);                  
    }
    
}