* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    /* background: var(--Background, rgba(176, 139, 187, 0.10)) !important; */
  }
  
  .container {
    width: 100%;
    max-width: 1412px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 25px;   
  }
  
  .steps {    
    width: 75%;
    display: flex;
    justify-content: space-between;    
    margin-bottom: 30px;
    align-items: center;
  }
  
  .step {       
    color: #9D9D9C;    
    background: var(--Blanc, #FFF);
    display: inline-flex;
    height: 33px;
    padding: 5px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;            
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .step.active {
    border-radius: 26px 0px 0px 26px;
    background: var(--Lavande-clair, #B08BBB);
    color: var(--Blanc, #FFF);
  }

  .step:first-child{
    border-radius: 26px 0px 0px 26px;
  }
  .step:last-child{
    border-radius: 0px 26px 26px 0px;
  }
  .line_step{       
    border: none; /* Remove default border */
    height: 1px;
    width: 100%;
    background: none; /* Remove background */    
    /* Create dashed line */
    border-top: 1px dashed #8D658A; /* Color for the dashed line */
  }
  
  .category-section {
    background-color: white;
    padding: 30px;
    padding-bottom: 0px;
    border-radius: 15px;
    text-align: center;
  }
  
  .category-section h2 {
    color: #8D658A;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  
  .category-section .highlight {
    color: #FF8366;
    font-weight: 900;    
  }
  .categories .badg_cate{
    display: inline-flex;
    transform: rotate(-5.446deg);
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Soft-gray, #F0ECE6);
    color: #8D658A;
    position: absolute;
    margin-left: 19px;
    margin-top: -22px;
  }
  .categories .badg_cate_selected{
    background: var(--Violet-doux, #8D658A) !important;
    color: #FFFFFF !important;
  }
  .check_icon{
    position: absolute;
    margin-left: -93px;
    margin-top: 121px;
  }
  
  .categories {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 20px;
    white-space: nowrap;    
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
    scrollbar-width: none;  
    padding-bottom: 9px;
  }
  .categories::-webkit-scrollbar {
    display: none;             /* Hide scrollbar in Chrome, Safari, and Opera */
  }
  .categories div {
    display: inline-block;      /* Ensure each span acts like an inline block */
    height: 100%;
  }
  
  .category {    
    display: inline-block;    
    height: 120px;    
    margin-right: 20px;
    border-radius: 15px;    
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #9b8a9f;
    position: relative;
    margin-top: 28px;
    cursor: pointer;
  }
  .category img{
    width: 143.295px;
    height: 136px;
    flex-shrink: 0;
    border-radius: 26px;    
  }
  .selected{

  }
  
  
 
  
  .checkmark {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-color: #6D396D;
    border-radius: 50%;
    display: inline-block;
  }
  
  .category-section p {
    font-size: 18px;
    font-weight: 600;
    color: #6D396D;
  }
  
  .next-button {        
    width: 188px;
    transform: rotate(-4.357deg);
    padding: 10px 30px;   
    border-radius: 26px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;    
    border: none;    
    cursor: pointer;    
  }
  
  .next-button:hover {
    background-color: #e56d46;
  }
  .button_color_no{
    background: var(--Disable-gradient, linear-gradient(90deg, #9D9D9C -27.35%, #E5E1DB 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
  }  
  .button_color_yes{
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }  

.alert{
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  align-items: flex-start;
  width: 700px;
  height: 50px;
  gap: 10px;
  border-radius: 26px;
  background: var(--Pche-clair, #FCAE89);
  color: var(--Blanc, #FFF);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  line-height: normal;  
}
.alert-section{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 40px;
}