.displayMobile{
    display: none ; 
}

@media (max-width: 425px) {
    .displayWeb {
        display: none !important; 
      }

    .displayMobile {
        /* display: flex;
        width: 172%; */
        display: inline-block;
        width: 375px;
        /* height: 100vh; */
        padding-left: 10px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        overflow-x: auto;             
    }

    .table_scroll {
        overflow-x: auto !important; 
        width: 1300px !important;
    }
    .config-popup-date-mobile-disp {
        display: flex
;
        width: 363px;
        padding: 12px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
        border-radius: 26px;
        border: 4px solid var(--Soft-gray, #F6F6F6);
        background: var(--Blanc, #FFF);
        height: auto;
    }

    .config-popup-overlay-mobile-Disp {
        position: fixed; /* Fixed to the viewport */
        top: 50%; /* Align vertically in the center */
        left: 50%; /* Align horizontally in the center */
        transform: translate(-50%, -50%); /* Shift the popup so it appears centered */
        width: 100%; /* Ensure it covers the entire viewport */
        height: 100%; /* Full height */
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
        backdrop-filter: blur(8px); /* Apply blur effect */
        display: flex; /* Flex container for centering */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
        overflow-y: auto;
        z-index: 1001;
    }

    .exclusivity-select-display-mobile {
        display: table;
        padding: 10px 20px;
        margin: auto;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 26px;
        background: var(--Soft-gray, #F6F6F6);
        width: auto; /* Ensure the box sizes based on content */
        white-space: nowrap; /* Prevent text from wrapping inside the box */
      }

}