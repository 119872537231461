
.footer2 {    
    display: flex;
    /* height: 443px; */
    height: 77%;
    padding: 9px 79px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0px;
    left: 70px;
    right: 50px;
    border-radius: 26px 26px 0px 0px;
    border-top: 2px solid var(--Pche-clair, #FCAE89);
    border-right: 2px solid var(--Pche-clair, #FCAE89);
    border-left: 2px solid var(--Pche-clair, #FCAE89);
    background: #FFF;      
    width: 100%;
    width: 90%;
    
}
.activeTabSelection{
    border-radius: 26px;
    background: #FFF1E5;
    font-weight: 700; 
}
.headerSelection{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    color: var(--Pche-clair, #FCAE89);

    /* Title/Accent */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 10px;
}

.selectionMarketing{
    display: flex;
    transform: rotate(-0.013deg);
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Soft-gray, #F6F6F6);

    color: var(--Violet-doux, #8D658A);

    /* Elements/Tag */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    min-width: 125px;
    float: right;
    white-space: nowrap;
}
.listeData{
    display: flex;
    /* height: 191px; */
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    border-radius: 26px;
    border: 1px solid var(--Pche-vive, #FF8366);
    width: 100%;
    /* margin-top: -53px; */
    margin-top: 10px;
    margin-bottom: 30px;
}


.listeDataChild{
    display: flex;
    /* height: 191px; */
    /* padding: 10px; */
    flex-direction: column;
    align-items: center;
    gap: 5px;
    border-radius: 26px;
    /* border: 1px solid var(--Pche-vive, #FF8366); */
    width: 100%;
    /* margin-top: 5px; */
    margin-bottom: 5px;
}

.listeData1{
    display: flex;
    height: 0px;
    border: none;
    width: 100%;
    margin-top: -120px;
    margin-bottom: 0px;
}
.listeData .div1{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #DCDCDC;

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.listeData .spanMois{
    display: flex;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    background: var(--Strong-gray, #9D9D9C);

    color: var(--Blanc, #FFF);

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.listeData .div2{
    /* width: 1210px;
    height: 33px; */
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Background--SEOA, #F2EEF3);
}

.div2 td{
    color: var(--Gray, #4C4C4C);

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 18px;
}
.backtd{
    background-color: var(--Gray, #F3F0EE) !important;
}
.backtdisplay{
    background-color: var(--Gray, #EEF3F2) !important;
}
.hideSelction{
    color: var(--Violet-doux, #8D658A);
    /* position: fixed; */
    /* Elements/Tag */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    background-color: #FFF;
    white-space: nowrap;
    
    
}
.hideSelction2{
    color: var(--Violet-doux, #8D658A);
    position: fixed;
    /* Elements/Tag */
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    background-color: #FFF;
    
    
}

.topmargin{
    margin-top: 10px;
}



.priceSelection  {
    margin-left: 10px;
    display: flex;                   
    padding: 0px 20px;    
    justify-content: center;
    align-items: center;
    gap: 10px;    
    border-radius: 26px;
    background: var(--Lavande-clair, #B08BBB);
    color: #FFF !important;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
}
.activePriceSelection {
    background: var(--Pche-vive, #FF8366);
    border-radius: 26px;
}

.textSelection{
    color: var(--Violet-doux, #8D658A);
text-align: center;

/* Desktop/Text/Accent */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
cursor: pointer;
}


.table_scrollSelection {
    height: auto !important;
    /* margin-bottom: 36px; */
    max-height: 800px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #8D658A #FFFFFF;
}

.footer2 .table_scrollSelection {
    white-space: nowrap; 
    height: 500px; 
    width: 100%; 
    display: flex; /* Use flexbox for horizontal layout */
    gap: 50px; /* Adds space between child elements */
    margin-bottom: 70px;
}

.footer2 .table_scrollSelection .seao-container,
.footer2 .table_scrollSelection .seao-container-aff,
.footer2 .table_scrollSelection .seao-container-display {
    min-width: 300px; /* Ensures each container has a minimum width */
    flex-shrink: 0; /* Prevents elements from shrinking */
}

.activeText .textSelection {
    color: var(--Pche-vive, #FF8366);
    text-align: center;
    
    /* Desktop/Text/Accent */
    font-family: Poppins;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    align-items: center;
    justify-content: center;
}

.large-button {
    display: flex;
    height: 46px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);

    /* Text styling */
    color: var(--Background-Blanc, #FFF);
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
}


.modal-valider button {
    display: flex;
    height: 40.05px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: var(--Background-Blanc, #FFF);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background: linear-gradient(90.07deg, #9d9d9c -27.35%, #e5e1db 77.87%);
    cursor: not-allowed;
    transition: background 0.3s ease, cursor 0.3s ease;
}

/* Active state */
.modal-valider button.active {
    background: var(--Active-gradient, linear-gradient(90deg, #ff8366 -27.35%, #fcae89 77.87%));
    cursor: pointer;
}

/* Hover effect for active state */
.modal-valider button.active:hover {
    background: linear-gradient(90deg, #fcae89 -12.46%, #ff8366 89.1%);
}
.modal-bon .cree_button{
    display: flex;    
    /* width: 202px; */
    height: 50px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;    
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    color: var(--Background-Blanc, #FFF);

    /* Elements/Button */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: initial;    
}


.modal-bon .cree_button2 {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 50px;
    padding: 10px 30px;
    gap: 10px;
    border-radius: 26px;
    box-shadow: 0px 4px 7.7px rgba(0, 0, 0, 0.09);
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    color: var(--Background-Blanc, #FFF);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: initial;
    /* Ensure the button takes up only as much space as it needs */
    width: auto; 
    margin: 0 auto; /* Center the button within its parent container */
}

.modal-bon .cree_button2:hover {
    background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
}

.modal-bon .fermer{ 
    display: flex;    
    /* width: 202px; */
    height: 50px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;            
    color: #FF8366;
    background: none;        
    display: flex;
    height: 50px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;    
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: initial;     
}

.bon_bon{
    color: #FF8366 !important;
}

.div-bg{
    display: flex;
    width: 830px;
    height: 32px;
    padding: 6px 18px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 26px;
    border-top: 1px solid var(--Background---AFFILIATION, #F3F0EE);
    background: var(--Blanc, #FFF);
}

.td-no-pad{
    padding: 8px 10px !important;
}

.footer {
    display: flex;
    padding: 9px 50px;
    /* width: 90%; */
    justify-content: center;
    align-items: center;
    /* gap: 150px; */
    border-radius: 26px 26px 0px 0px;
    background: var(--Blanc, #FFF);
    padding-top: 10px;
}

.tabs{
    margin-top: 26px;
}

td.custom-td-style {
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
td.custom-td-au {
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

td.custom-exclusivity-style {
    color: var(--Violet-doux, #8D658A); /* Use your color variable or fallback */
    font-family: 'Poppins', sans-serif; /* Ensure Poppins is imported */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
td.custom-price-style {
    color: var(--Violet-doux, #8D658A); /* Use your color variable or fallback */
    font-family: 'Poppins', sans-serif; /* Ensure Poppins is imported */
    font-size: 12px;
    font-style: normal;
    font-weight: 650;
    line-height: normal;
}

.footer2 .table_scroll {
    overflow: scroll !important;
    height: 500px;
}




/* new footer designe  */
.seao-container {
    display: flex;
    width: 400px;
    height: auto;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    border-radius: 26px;
    background: var(--Background--SEOA, #F2EEF3);
    margin-top: 26px;
}
.seao-container-aff {
    display: flex;
    width: 400px;
    height: auto;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 26px;
    background: var(--Background---AFFILIATION, #F3F0EE);
    margin-top: 26px;
}
.seao-container-display {
    display: flex;
    width: 410px;
    height: auto;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 26px;
    background: var(--Background---DISPLAY, #EEF3F2);
    margin-top: 26px;
}

.seao-header {
    display: flex;
    flex-direction: column;  /* Stack items vertically in header */
    align-items: center;     /* Center content horizontally */
    padding: 1px 73px;
    gap: 5px;                /* Space between the icon/title and link */
    align-self: stretch;
    border-radius: 26px 26px 0 0;
    background: #D9CDDC;
}
.seao-header-aff {
    display: flex;
    flex-direction: column;  /* Stack items vertically in header */
    align-items: center;     /* Center content horizontally */
    padding: 1px 73px;
    gap: 5px;                /* Space between the icon/title and link */
    align-self: stretch;
    border-radius: 26px 26px 0 0;
    background: #E3D3C8
}
.seao-header-display {
    display: flex;
    flex-direction: column;  /* Stack items vertically in header */
    align-items: center;     /* Center content horizontally */
    padding: 1px 73px;
    gap: 5px;                /* Space between the icon/title and link */
    align-self: stretch;
    border-radius: 26px 26px 0 0;
    background: #CFDAD8;
}

.seao-header > div {
    display: flex;
    flex-direction: row;     /* Align icon and title horizontally */
    align-items: center;     /* Vertically center icon and title */
    gap: 10px;               /* Space between the icon and title */
}
.seao-header-aff > div {
    display: flex;
    flex-direction: row;     /* Align icon and title horizontally */
    align-items: center;     /* Vertically center icon and title */
    gap: 10px;               /* Space between the icon and title */
}
.seao-header-display > div {
    display: flex;
    flex-direction: row;     /* Align icon and title horizontally */
    align-items: center;     /* Vertically center icon and title */
    gap: 10px;               /* Space between the icon and title */
}

.seao-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--Blanc, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensures the icon is contained within the circle */
}

.icon-img {
    width: 72%;
    height: 70%;
    object-fit: cover; /* Ensures the icon fits nicely within the container */
    border-radius: 50%; /* Ensures the image is circular */
}

.seao-title {
    color: var(--Blanc, #FFF);
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
}

.seao-link {
    color: var(--Soft-gray, #F6F6F6);
    font-family: Poppins, sans-serif;
    font-size: 8px;
    font-weight: 600;
    line-height: 150%; /* 12px */
    text-decoration: underline;
    text-decoration-line: solid;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    margin-top: -5px; 
    cursor: pointer;
}


.seao-content {
    display: flex;
    flex-direction: column;        /* Stack content vertically */
    align-items: center;           /* Center items horizontally */
    justify-content: center;       /* Center items vertically */
    gap: 0px;
    padding: 0px;
    text-align: center;            /* Ensure the text is centered */
}

.seao-content-item {
    color: var(--Strong-gray, #9D9D9C);  /* Set the color to the custom variable or fallback */
    font-family: Poppins, sans-serif;     /* Use Poppins font */
    font-size: 10px;                      /* Set the font size */
    font-style: normal;                   /* Set font style */
    font-weight: 500;                     /* Set font weight */
    line-height: normal;
}

.seao-content-item-two {
    color: var(--Gray, #4C4C4C);
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.seao-content-wrapper {
    display: flex;
    height: auto;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 0px 0px 26px 26px;
    border: 2px solid var(--Background--SEOA, #F2EEF3);
    background: var(--Blanc, #FFF);
}
.seao-content-wrapper-aff {
    display: flex;
    height: auto;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 0px 0px 26px 26px;
    border: 2px solid var(--Background--SEOA, #F2EEF3);
    background: var(--Blanc, #FFF);
    margin-top: -13px;
}
.seao-content-wrapper-display {
    display: flex;
    height: auto;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 0px 0px 26px 26px;
    border: 2px solid var(--Background--SEOA, #F2EEF3);
    background: var(--Blanc, #FFF);
    margin-top: -13px;
}

.seao-title-config {
    color: var(--Pche-clair, #FCAE89); /* Accent color */
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.config-selection-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap; /* Allow content to wrap when needed */
}

.config-selection-box-container {
    display: flex;
    flex-direction: column; /* Stack label and box vertically */
    align-items: center;
    width: 48%; /* Adjust as needed */
    margin-bottom: 20px; /* Add space between rows */
}

.config-selection-duration {
    color: var(--Strong-gray, #9D9D9C);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px; /* Space between label and box */
}

.active-selection {
    display: inline-flex;
    height: 38px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 26px;
    color: var(--Strong-gray, #9D9D9C);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: white; /* Adjust text color if needed */
}

.sixmoisSelected.active-selection {
    margin-left: -30px;
    margin-bottom: 0px;
}

.douzemoisSelected.active-selection {
    margin-right: -30px;
    margin-bottom: 0px;
}

.config-selection-box {
    display: flex;
    width: 183px;
    height: 40px;
    padding: 10px 30px;
    justify-content: space-between;
    align-items: center;
    border-radius: 26px;
    border: 1px solid var(--Active-gradient, #FF8366);
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}

.config-selection-container-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%; /* Adjust as needed */
    margin-bottom: 20px;
}

.config-selection-box-date {
    display: flex;
    padding: 2px;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    gap: 10px;
    align-self: stretch;
    border-radius: 26px;
    background: #F7F7F7;
}

.date-input-selection {
    width: 100%; /* Ensure it takes up the full width */
    border: none; /* Remove the default border */
    padding: 8px; /* Add padding to the input for better appearance */
    font-family: Poppins, sans-serif;
    font-size: 12px;
    border-radius: 26px; /* Match the container's rounded corners */
    box-sizing: border-box;
    background: transparent; /* Remove any background color */
    outline: none; /* Remove the outline if any */
    text-align: center; /* Center the text inside the input */
}

.date-input-selection input {
    background: transparent; /* Ensure the input background is transparent */
    border: none; /* Remove the input border */
    padding: 8px; /* Padding for input */
    font-family: Poppins, sans-serif;
    font-size: 12px;
    border-radius: 26px; /* Match the container's rounded corners */
    text-align: center; /* Ensure the date text is centered */
}

/* Add styles to the selected date */
.date-input-selection input:focus {
    color: var(--Pche-clair, #FCAE89); /* Apply the desired color on focus */
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
}

.date-input-selection input:active {
    color: var(--Pche-clair, #FCAE89); /* Apply color when the input is clicked */
}

.react-datepicker__input-container input {
    color: var(--Pche-clair, #FCAE89); /* Apply the color to the selected date */
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    text-align: center; /* Ensure selected date is centered */
}




.left {
    flex: 1;
}

.right {
    flex: 1;
}


.custom-container {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Blanc, #FFF);
    margin-top: -6px;
}

.custom-price {
    width: 134px;
    color: var(--Pche-vive, #FF8366);
    text-align: center;
    
    /* Desktop/Title/Accent */
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 14px;
}


.delete-campaign {
    color: var(--Pche-vive, #FF8366);
    
    /* Desktop/Detail/Detail */
    font-family: Poppins, sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 12px */
    
    text-align: center;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
}

.empty-selection-container {
    display: flex;
    justify-content: center;  /* Horizontally centers content */
    align-items: center;      /* Vertically centers content */
    height: 224px;            /* Take full viewport height */
    width: 100%;              /* Ensures it takes the full width */
}

.empty-selection-message {
    color: var(--Strong-gray, #9D9D9C);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center; /* Centers the text inside the <p> element */
}
