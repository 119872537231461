.mobileHome{
    display: none ; 
}
.seaoMobile{
    display: none ; 
}

@media (max-width: 425px) {
    .web-logo-header {
        display: none !important; 
      }
    .webHome {
        display: none !important; 
      }
    .seaoWeb {
        display: none !important; 
      }

      .mobileHome {
        display: flex;
        width: 100%;
        height: 100vh;
        padding: 50px 23px;
        flex-direction: column;
        align-items: center;
        gap: 20px;   
        overflow-x: hidden;  
    }

    


    .mobileHome .header {
        
        flex-direction: column;
        align-items: center;
        margin-top: -2px !important;
    }
    .search-container {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center align the container */
        gap: 15px; /* Space between the search input and the dropdown */
    }

    .search-bar {
        display: flex;
        width: 380px;
        height: auto;
        padding: 20px 10px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 0px 26px 26px 26px;
        background: #FFF;
        margin: 0 auto;
        margin-top: -10px;
        /* overflow: hidden; */
        overflow: visible;
    }

    .mobileHome .styled-text {
        width: 947px;
        position: absolute;
        left: 26px;
        /* top: 30.923px; */
        color: var(--Strong-gray, #9D9D9C);
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
        margin-top: 38px;
    }
    
    .mobileHome .styled-text p {
        color: var(--Strong-gray, #9D9D9C);       
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 10px;
        word-wrap: break-word;
        max-width: 34%;
       
    }


    .mobileHome  .input_search-mobile {
        display: flex;
        width: 351px;
        padding: 10px;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: #F7F7F7;
        margin-top: 47px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .mobileHome .category-filters-dropdown {
        display: flex; /* To ensure alignment for any content inside */
        position: relative;
        width: 351px; /* Matches the input width */
        padding: 10px;
        background: #F7F7F7;
        border-radius: 26px;
        cursor: pointer;
        margin-top: 14px; /* Adds space between input and category dropdown */
        margin-left: auto;
        margin-right: auto;
    }

    .mobileHome .dropdown-content {
        position: absolute;
        top: 110%;
        left: 24px;
        display: flex;
        width: 300px;
        height: auto;
        max-height: 427px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 10px 0;
        border-radius: 0px 0px 26px 26px;
        background: var(--Soft-gray, #F6F6F6);
        overflow-y: auto;
        z-index: 10;
    }

    .mobileHome .validate-button {
        display: flex;
        height: 40px; /* Updated height */
        padding: 10px 30px; /* Consistent padding */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        gap: 10px; /* Space between any child elements */
        flex-shrink: 0; /* Prevent shrinking */
        border-radius: 26px; /* Smooth rounded corners */
        background: var(--Soft-gray, #F6F6F6); /* Neutral background */
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09); /* Light shadow effect */
        border: none; /* Remove border */
        cursor: pointer; /* Pointer cursor on hover */
        margin: 10px auto; /* Center and provide spacing */
    }
    
    .mobileHome .validate-button span {
        color: var(--Pche-vive, #FF8366); /* Peachy color */
        font-family: Poppins, sans-serif; /* Modern font */
        font-size: 14px; /* Readable font size */
        font-style: normal; /* Default font style */
        font-weight: 500; /* Medium weight */
        line-height: normal; /* Normal line height */
    }
    /* .mobileHome .validate-button span:hover {
        color: var(--Pche-vive, #F6F6F6);
    } */

      
    .mobileHome .validate-button:hover {
        background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    }
    
    
    
    .top-bar {
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
        margin-top: 10px;
    }

    .top-bar .info-icon,
    .top-bar .logout {
        width: 30px;
        height: 30px;
    }

    .tabs-container {
        margin-top: 20px;
    }

    .tabs {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }

    .logo-left {
        width: 110px;
        height: 48px;
    }

    .top-bar .info-icon {
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .top-bar .logout {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .tabs-container {
        margin-top: 10px;
    }



    .full-page-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 1000;
        display: flex;
        overflow-y: auto;
        flex-direction: column;
    }
      .full-page-modal-content {
        text-align: center;
        padding: 23px;
        /* margin-top: auto; */
      }
      .info-icon {
        cursor: pointer;
      }

      .retour-button {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px 8px;
        margin: 10px; /* Add spacing from edges */
        border-radius: 26px;
        background: linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%);
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        border: none;
        color: white;
        cursor: pointer;
        margin-right: auto;
      }
      
      .retour-button svg {
        flex-shrink: 0; /* Ensures the icon doesn’t shrink */
      }

      .retour-button:hover {
        background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    }


      .seo-title-mobile {
        color: var(--Gray, #4C4C4C);
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      
      .seo-highlight {
        color: var(--Pche-vive, #FF8366);
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .image-container-wrapper {
        display: flex;
        justify-content: center;  /* Centers the container horizontally */
        align-items: center;      /* Centers the container vertically */
        width: 100%;
        /* height: 30vh; */
      }

      .image-container {
        position: relative;
        width: 144px;
        height: 216px;
        flex-shrink: 0;
        border-radius: 8px;
        background: url('/public/assets/images/info/info.png') transparent 50% / cover no-repeat;
        box-shadow: 1px 1px 17.6px 10px rgba(157, 157, 156, 0.10);
        margin-top: 16px;
    }
    
    .background-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 173px;
        height: 68px;
        transform: translate(-7%, 145%);
        background: rgba(176, 139, 187, 0.20);
        border-radius: 8px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-info{
        align-self: stretch;
        color: var(--Gray, #4C4C4C);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .secondPInfo{
        align-self: stretch;
        color: var(--Pche-vive, #FF8366);
        text-align: center; 
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .image-container-affiliation {
        width: 231px;
        height: 353px;
        flex-shrink: 0;
        background: url('/public/assets/images/info/info_affiliation.png') lightgray 50% / cover no-repeat;
        box-shadow: 1px 1px 17px 10px rgba(0, 0, 0, 0.10);
        position: relative;
        /* Needed for the overlay to be positioned correctly */
    }
    
    .background-overlay-affiliation {
        width: 284.244px;
        height: 100.246px;
        transform: rotate(-6.211deg);
        flex-shrink: 0;
        border-radius: 8px;
        background: rgba(176, 139, 187, 0.20);
        position: absolute;
        top: 70%;
        left: -20px;
    }
    

    .image-row {
        display: flex;
        flex-direction: column; /* Stack images vertically */
        gap: 20px; /* Adjust the space between the image blocks */
        /* margin-top: 20px; */
        /* display: flex; */
        justify-content: center;  /* Centers the container horizontally */
        align-items: center; 
    }
    
    .image-block {
        text-align: center;
        /* Centers the title and image */
    }
    
    .image-title {
        color: var(--Pche-vive, #FF8366);
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 10px;
        /* Adds space between the title and image */
    }
    
    /* Style for each image */
    .image-covering-mobile,
    .image-banner,
    .image-bloc {
        width: 161px;
        height: 123px;
        flex-shrink: 0;
        background: lightgray 50% / cover no-repeat;
        border-radius: 8px;
        /* Optional: Rounds the image corners */
        margin-bottom: 15px; /* Add space below each image */
    }
    
    .image-covering-mobile .background-overlay {
        width: 181px;
        height: 35.542px;
        transform: translate(317%, 714%);
        left: -478px;
        top: 223px;
    }
    
    .image-banner .background-overlay {
        width: 167.5px !important;
        height: 39px !important;
        transform: translate(416%, 834%) !important;
        left: -593px;
        top: 364px;
    }
    
    .image-bloc .background-overlay {
        width: 67.3px !important;
        height: 71px !important;
        transform: translate(1431%, 493%) !important;
        left: -748px;
        top: 540px;
    }
    
    
    /* Specific background images for each block */
    .image-covering-mobile {
        background: url('/public/assets/images/info/display1.png') lightgray 50% / cover no-repeat;
    }
    
    .image-banner {
        background: url('/public/assets/images/info/display2.png') lightgray 50% / cover no-repeat;
    }
    
    .image-bloc {
        background: url('/public/assets/images/info/display3.png') lightgray 50% / cover no-repeat;
    }

    .mobileHome .logout {
        display: flex;
        width: 50px;
        height: 27px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        cursor: pointer;
    }
    
    .logout svg {
        width: 12px;   /* Set the width of the SVG icon */
        height: 12px;  /* Set the height of the SVG icon */
        fill: #fff;    /* Set the color of the SVG icon (optional) */
    }

    .mobileHome .logout:hover {
        background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    }
    



    .seaoMobile {
        display: inline-block;
        width: 372px;      
        flex-direction: column;
        align-items: center;
        gap: 20px;
        overflow-x: auto;    
        padding-left: 10px; 
        
    }

    .table_scroll {
        overflow-x: auto !important; 
        width: 1300px !important;
    }

    /* .table-site{
        overflow-x: scroll; 
    } */

    .table_header .row_header {
        font-size: 12px; /* Reduce font size for headers */
        padding: 13px; /* Reduce padding */
      }
    
      /* Adjust column widths for mobile */
      .table-header .row_header:nth-child(1),
      .table-header .row_header:nth-child(2),
      .table-header .row_header:nth-child(3),
      .table-header .row_header:nth-child(4),
      .table-header .row_header:nth-child(5),
      .table-header .row_header:nth-child(6) {
        width: auto;
      }
    
      /* Stack columns vertically for mobile */
      .table_row2 {
        display: block;
        padding: 10px 0;
      }

      .table-category{
        font-size: 10px;
      }

      .table-categoryDisp {
        /* width: 8%; */
        width: 428px;
        margin-left: 3%;
        text-wrap: nowrap;
    }

    .table-prcDisp{
        width: 10%;
    }

    .table-categoryDisp{
        font-size: 12px;
      }

      .dropdown-toggle-mobile {
        color: var(--Violet-doux, #8D658A); /* Custom violet color */
        font-family: Poppins, sans-serif; /* Font family */
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between; /* Pushes elements to far edges */
        width: 100%; /* Ensure the container takes up the full width */
    }
    
    .dropdown-toggle-mobile svg {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
    }

    .table-mts{
        width: 15%;
    }

    .table-nb-page-mobile{
        width: 40%;
        text-align: center;
    }

    
      .table_row2 .table-category,
      .table_row2 .table-nb-page,
      .table_row2 .table-request,
      .table_row2 .table-impressions {
        display: block;
        /* width: 100%; */
        text-align: left;
      }
    
      /* Adjust icons to be smaller or hidden */
      .svg_plus {
        width: 10px;
        height: 10px;
      }
    
      /* Hide non-essential columns on mobile */
      .table-header .row_header:nth-child(4),
      .table-header .row_header:nth-child(5),
      .table-header .row_header:nth-child(6) {
        display: none;
      }
    
      /* Reduce size of table items and make them more compact */
      .under_table td,
      .under_table th {
        font-size: 12px;
        padding: 5px;
      }
    
      /* Accordion adjustments for mobile */
      .table_scroll {
        padding-bottom: 20px;
      }
    
      .table-scroll .table_row2.selectedSeao {
        padding-bottom: 10px;
      }

      .siteMobile{
        margin-right: 250px;
      }
    
      .under_row {
        margin-top: 10px;
      }
    
      /* Reduce button size for mobile */
      .custom-button {
        font-size: 14px;
        padding: 8px 16px;
      }
    
      .loadMoreBoutton {
        font-size: 14px;
        padding: 8px 16px;
      }
    

    /* popUp */

    .config-popup-overlay-mobile {
        position: fixed; /* Fixed to the viewport */
        top: 50%; /* Align vertically in the center */
        left: 50%; /* Align horizontally in the center */
        transform: translate(-50%, -50%); /* Shift the popup so it appears centered */
        width: 100%; /* Ensure it covers the entire viewport */
        height: 100%; /* Full height */
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
        backdrop-filter: blur(8px); /* Apply blur effect */
        display: flex; /* Flex container for centering */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
        z-index: 1001;
    }

    .config-popup-content-mobile {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        width: 90%; /* Adjust width for mobile */
        max-width: 400px; /* Prevent it from being too wide */
        padding: 20px;
        border-radius: 16px;
        max-height: 100vh;
    }

    .config-popup-container-date-mobile {
        justify-content: space-between;
        align-items: center;
        width: 59%;
    }

    .config-popup-box-date-mobile {
    display: inline;
    width: 200px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 26px;
    background: #F7F7F7;
}


    

    .button-container-mobile {
        display: flex; /* Align items horizontally */
        justify-content: space-between; /* Distribute buttons evenly */
        gap: 10px; /* Add spacing between buttons */
        width: 100%; /* Ensure it takes full width */
    }

    .custom-buttonRetour-mobile {
        display: flex;
        height: 40.05px;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: var(--Soft-gray, #F6F6F6);
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        color: var(--Pche-vive, #FF8366); /* Text color */
        font-family: Poppins, sans-serif; /* Font family */
        font-size: 14px; /* Font size */
        font-style: normal; /* Normal font style */
        font-weight: 500; /* Medium font weight */
        line-height: normal; /* Normal line height */
        cursor: pointer; /* Add pointer cursor for better UX */
        border: none; /* Remove border */
    }

    .validate-button-mobile {
        display: flex;
        height: 40.05px;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        color: white; /* Ensure text is visible on gradient */
        font-family: Poppins, sans-serif; /* Use Poppins font */
        font-size: 14px; /* Adjust font size */
        font-style: normal; /* Normal font style */
        font-weight: 500; /* Medium weight */
        line-height: normal; /* Standard line height */
        cursor: pointer; /* Pointer cursor for interaction */
        border: none; /* Remove border */
    }
    
    

    .config-popup-date-mobile {
        display: flex;
        width: 354px;
        padding: 30px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
        border-radius: 26px;
        border: 4px solid var(--Soft-gray, #F6F6F6);
        background: var(--Blanc, #FFF);
    }
    

    .config-popup-container {
        flex-direction: column; /* Stack elements vertically */
        gap: 15px; /* Add spacing between elements */
    }

    .button-container {
        flex-direction: column; /* Stack buttons vertically */
        gap: 10px; /* Add spacing between buttons */
    }

    .custom-buttonRetour, .validate-button {
        width: 100%; /* Make buttons full-width */
        padding: 10px; /* Adjust padding */
    }

    .config-popup-title {
        font-size: 18px; /* Slightly smaller title font size */
    }

    .date-input {
        font-size: 12px; /* Adjust input font size for readability */
        padding: 5px; /* Adjust padding for input field */
    }

    .config-popup-p {
        font-size: 10px; /* Adjust font size for small texts */
    }

    .fixed-div {
        display: flex;
        height: 40.05px;
        padding: 10px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 26px;
        background: linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%);
        box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
        color: #FFF; /* Corresponding to var(--Background-Blanc, #FFF) */
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: fixed;
        bottom: 20px; /* Distance from the bottom */
        right: 20px; /* Distance from the right */
        z-index: 1000; /* Ensure it stays above other elements */
        cursor: pointer; /* Optional: Makes it look clickable */
        text-align: center; /* Ensures text is centered */
      }
      
      .fixed-div:hover {
        background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    }
      
    .no-results-message {
        padding: 10px; /* Even smaller padding for very small screens */
        font-size: 16px; /* Increase font size for better visibility */

        width: 365px;
      }


      /* .table_header_mobile{
        padding-right: 98px !important;
      } */
  }