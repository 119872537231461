* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;    
}

body {     
    font-family: 'Poppins', sans-serif !important;           
    background: var(--Background, rgba(176, 139, 187, 0.10)) !important;
}

.container {          
    margin: 0 auto;
    padding: 20px;
}

.header {
    
    padding: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    margin-top: -50px;
    
}
.header .info-icon{
    /* float: left; */    
    margin-top: 34px;
    margin-right: 13px;
}

.info-icon{
    cursor: pointer;
}
.header .logout{
    /* float: right;  */   
    margin-top: 34px;
    margin-right: 13px;

    color: var(--Pche-vive, #FF8366);

    /* Elements/Button */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.tab.active {
    background-color: #FFFFFF;   
    font-weight: 700; 
}

.search-bar {         
    border-radius: 26px;
    background: var(--Blanc, #FFF);
    padding: 55px 34px;    
    height: 130px;
}
/* .search-bar .section-bar input {    
    border: none;
    display: inline-flex;
    padding: 4px 118px 6px 17px;
    align-items: center;
    border-radius: 26px;
    background: #F7F7F7;
} */
.search-bar .section-bar{
    display: flex;   
    justify-items: center;
    align-items: center;
    width: 1206px;
    height: 29px; 
}

.search-bar .section-bar input{
    color: var(--Strong-gray, #9D9D9C);    
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.search-bar .input_search{
    display: flex;    
    width: 517px;
    padding: 10px;
    align-items: center;
    gap: 10px;    
    /* margin-bottom: 40px; */
    border-radius: 26px;
    background: #F7F7F7;
    margin-right: 20px;
    
}
.search-bar input {                               
    display: inline-flex;
    border: none;
    padding: -1px 118px 6px 17px;
    align-items: center;
    border-radius: 26px;
    background: #F7F7F7;
    color: var(--Strong-gray, #9D9D9C);    
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.section-bar .section-input{
    margin-top: 8px;
    margin-right: 21px;
}
.search-keywords {
    width: 100%;
    border: none; /* Initially no border */
    outline: none; /* Remove the default focus outline */
}
  
.search-keywords:focus {
outline: none; /* Ensure no outline is shown on focus */
border: none; /* Ensure no border is shown on focus */
}
.category-filters {
    margin-top: 10px;
}

.category-filters span {
    color: var(--Violet-doux, #8D658A);
    border-radius: 26px;
    background: var(--Soft-gray, #F6F6F6);        
    margin-right: 10px;
    display: inline-block;
    transform: rotate(-0.013deg);
    padding: 5px 10px;  
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.category-filters {
    display: flex;             /* To arrange items horizontally */
    overflow-x: auto;          /* Enable horizontal scrolling */
    white-space: nowrap;       /* Prevent items from wrapping to a new line */
    padding: 10px;             /* Optional: adds some padding */
    gap: 10px;                 /* Optional: adds space between the elements */
    scrollbar-width: thin;     /* Optional: adjusts scrollbar width in Firefox */
}

.category-filters {
    display: flex;             /* Arrange items horizontally */
    overflow-x: auto;          /* Enable horizontal scrolling */
    white-space: nowrap;       /* Prevent wrapping */
    padding: 10px;             /* Optional: adds some padding */
    gap: 10px;                 /* Optional: adds space between the elements */
    -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
    scrollbar-width: none;     /* Hide scrollbar in Firefox */
}
.category-filters::-webkit-scrollbar {
    display: none;             /* Hide scrollbar in Chrome, Safari, and Opera */
}
.category-filters span {
    display: inline-block;      /* Ensure each span acts like an inline block */
}

.table-site {
    /* margin-top: 20px;         */
    /* padding: 20px;   */  
    display: flex;   
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-end;
    gap: 7px;    
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    margin-bottom: 10px; 
    /* width: 1240px; */    
}
.table-site .table_row{
    display: flex;
    justify-content:space-between;
    align-items: center; 
    padding: 8px;  
    margin-bottom: 5px;      
    width: 1300px;    
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Blanc, #FFF);
    height: 46px;
    color: var(--Gray, #4C4C4C);

    display: grid;
    grid-template-columns: repeat(9, 1fr); 
    align-items: center;     
    border-bottom: 1px solid #ddd; 
}

.table_row div {
    padding: 5px; /* Padding for each cell */
    text-align: center; /* Center the text horizontally */  
}

.table_row .plus svg {
    margin-right: 11px; 
}
.table_header .arrow-container {
    margin-right: -8px;
}
.table_header .arrow-container svg {
    display: block; 
    margin-top: 2px;   
        
}
.table-site .table_header{
    display: flex;
    justify-content:space-between;
    align-items: center; 
    /* padding: 8px; */
    padding: 0px;
    
    width: 1300px;    
    flex-shrink: 0;       
    height: 46px;

    display: grid;
    grid-template-columns: repeat(9, 1fr);        
    color: var(--Strong-gray, #9D9D9C);       
}

.table_header .row_header {
    display: flex;
    padding: 5px;    
    /* justify-content: center; */
    align-items: center;
    text-wrap:nowrap;
    font-weight: 500;
}

.table_header svg {
    margin-right: 11px; 
}

.under_table{
    padding-left: 10px;    
}

.under_row{
    flex-shrink: 0;
    border-radius: 0px 0px 26px 26px;
    background: var(--Blanc, #FFF);     
    padding-bottom: 15px;   
    margin-bottom: 14px; 
}

.under_row .input_search{
    display: flex;    
    width: 517px;
    height: 28px;
    padding: 10px;
    align-items: center;
    gap: 10px;    
    margin-bottom: 40px;
    border-radius: 26px;
    background: #F7F7F7;
    margin-top: 10px;          
    flex-shrink: 0;    
}
.under_row .input_search input{
    color: var(--Strong-gray, #9D9D9C);    
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    display: inline-flex;
    border: none;
    padding: 4px 118px 6px 17px;
    align-items: center;
    border-radius: 26px;
    background: #F7F7F7;        
    font-family: 'Poppins';       
}

.under_row .sous_row{
    display: flex;
    justify-content:space-between;
    align-items: center; 
    padding: 8px;  
    margin-bottom: 5px;      
    width: 1290px;    
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Background, rgba(176, 139, 187, 0.10)) !important;
    height: 46px;
    color: var(--Gray, #4C4C4C);
    display: grid;
    grid-template-columns: repeat(7, 1fr); 
    align-items: center;     
    border-bottom: 1px solid #ddd; 
}
.sous_row div {
    padding: 5px; /* Padding for each cell */
    text-align: center; /* Center the text horizontally */  
}
.group_cell{
    display: flex;
    justify-content: space-around;
    border-radius: 26px;
    /* background: var(--Background, rgba(176, 139, 187, 0.10)) !important; */
    width: 302px;    
    flex-shrink: 0;
}
.group_cell_header{
    display: flex;
    justify-content: space-between;        
    width: 302px;    
    flex-shrink: 0;
}

.under_row .under_table_header{
    display: flex;
    justify-content:space-between;
    align-items: center; 
    padding: 8px;  
    margin-bottom: -7px;      
    width: 1290px;    
    flex-shrink: 0;       
    height: 46px;
    display: grid;
    grid-template-columns: repeat(7, 1fr); 
    align-items: center;   
    color: var(--Strong-gray, #9D9D9C);           
}
.under_row .under_table_header .row_header {
    display: flex;
    padding: 13px !important; /* Padding for each cell */    
    justify-content: center;
    align-items: center;
    text-wrap:nowrap;
    font-weight: 500;
}


.under_row .under_table_header .arrow-container svg {
    display: block; 
    margin-top: 2px;      
        
}
.under_table_header svg {
    margin-right: 9px;     
}

.sous_row .select_option select{
    all: unset;
}
.sous_row .select_option{  
    display: block;          
    padding: 3px 5px;
    width: 101px;
    height: 23px;
    /* width: 67px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 16px;
    /* border: 1px solid var(--Violet-doux, #8D658A); */
    background: var(--Blanc, #FFF); 
    color: #9D9D9C; 
}
.sous_row .row_select{
    display: flex;
    text-align: end !important;
    justify-content: space-between;
}
.row_select .select_option svg{
    margin-left: 4px;
    color: #9D9D9C;
}
.sous_row .text_cell{
    color: var(--Violet-doux, #8D658A);    
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.icon_shoppign svg{
    color: #FFFFFF;
}
.icon_shoppign{
    color: #FFFFFF;
    display: flex;
    width: 42px;
    height: 20px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}
.shoppign{
    display: flex;
    justify-content: center;
}

.under_row .input_search {
    margin-left: 18px;
    margin-bottom: 15px;
}

.ccordion_item {
    all: unset; /* Remove all styles, including margin, padding, etc. */
    display: inline-block; /* Ensure it behaves as a button */
    cursor: pointer; /* Keep the pointer cursor for usability */
}

.scroll_table {
    display: block;            /* Arrange items vertically */
    overflow-y: auto;          /* Enable vertical scrolling */            
    max-height: 326px;         /* Set a maximum height for the container */
    scrollbar-width: thin;     /* Optional: adjusts scrollbar width in Firefox */
}

/* Hide scrollbar for different browsers */
.scroll_table {
    -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
    scrollbar-width: none;     /* Hide scrollbar in Firefox */
}

.scroll_table::-webkit-scrollbar {
    display: none;             /* Hide scrollbar in Chrome, Safari, and Opera */
}

.scroll_table span {
    display: block;            /* Ensure each span takes up full width */
    /* margin-bottom: 10px; */       /* Optional: adds space between vertical elements */
}


.table_scroll {
    max-height: 95vh; /* Adjust to fit 80% of the viewport height dynamically */
    overflow-y: auto !important;  
    overflow-x: hidden; /* Disables horizontal scrolling, adjust if needed */

    /* Custom Scrollbar Styles */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #8D658A #FFFFFF; /* For Firefox */
    margin-bottom: 50px !important;
}

.table_scroll::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.table_scroll::-webkit-scrollbar-track {
    background: #FFFFFF; /* Background of the scrollbar track */
}

.table_scroll::-webkit-scrollbar-thumb {
    background: #8D658A; /* Color of the scrollbar thumb */
    border-radius: 20px; /* Rounded corners of the scrollbar thumb */
}

.table_scroll::-webkit-scrollbar-thumb:hover {
    background: #7a4e6c; /* Color of the scrollbar thumb on hover */    
}




.title{
    color: var(--Violet-doux, #8D658A);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.tab{    
    display: inline-flex;
    padding: 5px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 26px 26px 0px 0px;
    /* background: #FFF; */
    background: transparent;
    cursor: pointer;
    border: none;
    margin-right: 10px;    
    color: var(--Pche-vive, #FF8366);    
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.tab .activ{    
    display: inline-flex;
    padding: 5px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 26px 26px 0px 0px;
    background: #FFF;
    cursor: pointer;
    border: none;
    margin-right: 10px;
}

.footer {    
    display: flex;    
    justify-content: center;
    align-items: center;
    gap: 50px;
    border-radius: 26px 26px 0px 0px;
    background: var(--Blanc, #FFF);   
    padding-top: 10px;    
    position: fixed; 
    bottom: 0;          
    /* right: 0;        */
    width: 90%;
    z-index: 1000;    
    max-width: 1372px;
}

.button-group {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.button-group div { 
    display: flex;        
    border: none;
    color: white;
    padding: 15px 25px;
    text-align: center;      
    color: var(--Violet-doux, #8D658A);
    text-align: center;    
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    align-items: center;
    justify-content: center;
}

.price {
    margin-left: 10px;
    display: flex;                   
    padding: 0px 20px;    
    justify-content: center;
    align-items: center;
    gap: 10px;    
    border-radius: 26px;
    background: var(--Lavande-clair, #B08BBB);
    color: #FFF !important;
}

.large-button { 
    display: flex;
    color: #FFF !important;    
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;    
    border-radius: 26px;
    border:none;
    background: var(--Disable-gradient, linear-gradient(90deg, #9D9D9C -27.35%, #E5E1DB 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}

.large-button:hover {
    border-radius: 26px;
    background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}

.zone-date{
    display: flex;
    /* width: 198px; */
    height: 28px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 26px;
    background: #F7F7F7;

    gap: 10px;    
    margin-bottom: 40px;

    height: 28px;
    margin-top: 10px;          
    flex-shrink: 0;   
    margin-left: 50px;
    cursor: pointer;
}
.zone-date-svg{
    width: 12px;
    height: 12px;
    flex-shrink: 0;
}
.zone-date-svg svg{
    margin-bottom: 10px;
}
.zone-date-input{
    width: 96px;
    flex-shrink: 0;
    color: var(--Strong-gray, #9D9D9C);

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.zone-date-button{
    display: flex;
    width: 26px;
    height: 20px;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}

.zone-date-button svg{
    width: 12px;
    height: 12px;
    flex-shrink: 0;
}

.modal-date{
    border-radius: 26px;
    border: 1px solid var(--Soft-gray, #F6F6F6);
    background: #F7F7F7;
}
.modal-title{
    color: var(--Gray, #4C4C4C);

    /* Title/Accent */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 20px;
}
.modal-save{
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
    align-self: stretch;
}
.modal-save button{
    display: flex;
    width: 202px;
    height: 50px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);

    color: var(--Background-Blanc, #FFF);

    /* Elements/Button */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: initial;
}
.modal-resv-save button{
    display: flex;
    width: 202px;
    height: 50px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);

    color: var(--Background-Blanc, #FFF);

    /* Elements/Button */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: initial;
}
.modal-close{
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
}
.react-datepicker__header {
    text-align: center;
    background-color: var(--Blanc, #FFF);
    border-bottom: unset;
}

.react-datepicker__day--in-selecting-range{
    background-color: rgba(252, 174, 137, 0.40) !important;
}
.react-datepicker__day:not([aria-disabled=true]):hover, .react-datepicker__month-text:not([aria-disabled=true]):hover, .react-datepicker__quarter-text:not([aria-disabled=true]):hover, .react-datepicker__year-text:not([aria-disabled=true]):hover,
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    color: var(--text-secondary, #6A6A6D);
    color: #fff;
    border-top: 0.5px solid rgba(252, 174, 137, 0.40);
    border-bottom: 0.5px solid rgba(252, 174, 137, 0.40);
    background: rgba(252, 174, 137, 0.40);
}
.react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end{
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 24px;
    background-color: #FF8366 !important;
}

.rc-slider-rail{
    background-color: '#FCAE89' !important;
}

.blurred {
    filter: blur(5px); 
    pointer-events: none; 
    opacity: 0.5; 
}

.liste-content {
    transition: all 0.3s ease; /* Smooth transition for the blur effect */
}


.branding {
    display: flex;
    align-items: center; /* Vertically align items */
    justify-content: center;
  }
  
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
 
  

  




.selectedCategorie {
    background: #8d658a !important;
    color: #fff !important;
}


.styled-text {
    /* width: 947px; */
    /* position: absolute; */
    left: 127px;
    /* top: 30.923px; */
    color: var(--Strong-gray, #9D9D9C);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    margin-top: -60px;
}
.styled-text-chose {
    /* width: 947px; */
    /* position: absolute; */
    left: 127px;
    /* top: 30.923px; */
    color: var(--Strong-gray, #9D9D9C);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    margin-top: -60px;
    white-space: nowrap;
}

.mt-4 {
    margin-top: -1.5rem !important;
}


.logo-left {
    width: 67px;
    height: 30px;
    margin-right: 10px;
}

.lineOne {
    width: 2px;
    height: 35px;
    background: var(--Violet-doux, #8D658A);
    margin: 0 10px;
}
.campaign-text {
    color: var(--Violet-doux, #8D658A);
    /* font-family: 'Poppins', sans-serif; */
    font-size: 12px; /* Adjusted font size */
    font-weight: 600;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}




.category-filters-dropdown {
    /* overflow-x: scroll; */
    position: relative;
    width: 273px;
    padding: 12px;
    background: #F7F7F7;
    border-radius: 26px;
    cursor: pointer;
    margin-right: 20px;
}

.dropdown-toggle {
    color: var(--Violet-doux, #9D9D9C); /* Custom violet color */
    font-family: Poppins, sans-serif; /* Font family */
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px; /* Ensures appropriate spacing between text and icon */
}

.dropdown-toggle svg {
    width: auto;
    height: auto;
    flex-shrink: 0;
}

.dropdown-content {
    position: absolute;
    top: 110%;
    left: 0px;
    display: flex;
    width: 269px;
    max-height: 427px;
    flex-direction: column;
    align-items: center;
    gap: 16px; /* Space between items */
    padding: 10px 0; /* Space around the dropdown items */
    border-radius: 0px 0px 26px 26px;
    background: var(--Soft-gray, #F6F6F6);
    overflow-y: auto;
    z-index: 10;
}

/* .dropdown-content::-webkit-scrollbar {
    display: none;
} */
.dropdown-content::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .dropdown-content::-webkit-scrollbar-thumb {
    background-color: #8D658A; /* Background color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  }
  
  .dropdown-content::-webkit-scrollbar-track {
    background-color: #F6F6F6; /* Background color of the scrollbar track */
    border-radius: 10px;
  }

.dropdown-toggle::after {
    content: none;
}

.dropdown-item {
    display: flex;
    padding: 10px 11px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    margin: 0 10px;
    border-radius: 26px;
    background: var(--Blanc, #FFF);
    color: var(--Violet-doux, #8D658A);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    width: auto;
}

.dropdown-item:hover {
    background: #8d658a !important;
    color: #fff !important;
}

.categories-row {
    overflow-x: scroll;
    display: flex;
    gap: 9px;
    padding: 0 75px;
    height: 42px;
    white-space: nowrap;
    flex-wrap: nowrap;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;  
    scroll-behavior: smooth;

    scrollbar-width: none;
    -ms-overflow-style: none;
    
}

.scroll-group {
        display: flex; /* Aligns children in a row */
        align-items: center; /* Vertically centers the items */
        justify-content: space-between; /* Pushes text to the left and icons to the far right */
        gap: 967px;
        
}
.categories-row::-webkit-scrollbar {
    display: none;
}

.scroll-container {
    display: flex; /* Align scroll icons horizontally */
    justify-content: flex-end; /* Pushes icons to the right */
    gap: 5px; /* Space between icons */
    margin-top: -70px;
}

.scroll-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px; /* Adjust to ensure enough space for the SVG */
    height: 30px; /* Matches width for a consistent shape */
    border-radius: 26px;
    background: linear-gradient(90deg, #9D9D9C -27.35%, #E5E1DB 77.87%);
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    cursor: pointer;
    margin-left: 5px; /* Space between scroll icons */
}

.scroll-icon svg {
    display: block; /* Ensures the SVG respects its container */
}

.scroll-icon.active {
    background: linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%);
}
.scroll-icon.active:hover {
    background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
}

.scroll-icon.disabled {
    cursor: not-allowed;
}


.category-item {
    cursor: pointer;
    transform: rotate(-0.013deg);
    padding: 11px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Soft-gray, #F6F6F6);
    color: var(--Violet-doux, #8D658A);
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.selectedCategorie {
    background: #FF8366;
}

.header .info-icon {
    margin-top: 34px;
    margin-right: 13px;
}

.search-val{
    color: var(--Violet-doux, #8D658A) !important;
}


.no-results-message {
    display: flex;
    height: 420px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--Blanc, #FFF);
    text-align: center;
    padding: 20px;
    color: var(--Strong-gray, #9D9D9C);
    font-size: 12px;
    font-weight: 500;
  }