* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;    
}

body {
    background: var(--Background, rgba(176, 139, 187, 0.10));
    font-family: 'Poppins' !important;
    font-style: normal;
}

.contanaire {
    display: flex;
    width: 100%;  
    padding-top: 20px;
    padding-left: 137px;
    padding-right: 137px;
    justify-content: center;
    align-items: center;
    /* width: 1412px; */
    height: 746px;
    gap: 0px;
    opacity: 1;
  
}

.left-section {    
    background: var(--Violet-doux, #8D658A);        
    flex: 0 0 40%;    
    display: inline-flex;   
    justify-content: space-between;    
    border-radius: 26px 0px 0px 26px;    
    padding: 34px 57px 62px 41px;
    flex-direction: column;
    align-items: flex-start;
    gap: 74px;      
    width: 586px;        
    height: 746px;        
    gap: 10px;    
    opacity: 1;

}
.left-section #ads_svg{
    margin-left: 70px;
}

.left-section .branding {
    display: flex;
    align-items: center;
    gap: 30px;            
}
.branding{
    margin-left: 16px;
    margin-top: 20px;
}

.left-section .branding p{
    color: var(--Blanc, #FFF);    
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;    
    line-height: normal;
    margin-left: -5px;
    white-space: nowrap;
}
.left-section .branding .line{
    width: 1px;
    height: 48px;
    background: #FFF;
}

.line {
    margin-left: -7px;
    margin-right: 10px;
}

.left-section .branding img {
    width: 150px;
    margin-bottom: 20px;
}



.left-section .slogan h2 {
    color: var(--Blanc, #FFF);
    font-family: 'Poppins' !important;
    font-size: 36px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
}

.left-section .slogan span {
    color: var(--Pche-vive, #FF8366);
}
.left-section .section_img{
    width: 100%;
    display: flex;
    justify-content: center;
}
.left-section .section_img img{
    width: 313px;
    height: 306px;
    flex-shrink: 0;
}
.right-section {

    display: flex;   
    flex: 0 0 60%; 
    width: 852px;
    height: 746px;
    padding: 50px 57px;
    margin-left: -25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 27px;
    flex-shrink: 0;
    border-radius: 26px;
    background: #FFF;
}

.form-header p{
    color: var(--Violet-doux, #8D658A);
    text-align: right;    
    font-family: 'Poppins' !important;
    font-size: 12px !important;     
    font-style: normal; 
    font-weight: 500 !important;   
    line-height: normal;

    /* margin-right: -130px; */
}
.form-header .lien{
    color: var(--Violet-doux, #8D658A);                
    font-weight: 900 !important;
    line-height: normal;
    text-decoration: none;
}

.form-header{
    width: 100%;        
}
.form-header p button{
    all:unset;
    cursor: pointer;
}

.input-group label {       
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--Gray, #4C4C4C);
    /* Text/Accent */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.input-group input {        
    border: none;       
    display: flex;
    height: 38px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 26px;
    background: #F7F7F7;
    height: 38px;
    width: 100%;

    color: var(--Violet-doux, #8D658A);
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 700 ;
    line-height: normal;
}
input{    
    outline: none; /* Ensure no outline is shown on focus */
    border: none; /* Ensure no border is shown on focus */        
}

.checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    margin-top: 10px;
}

.checkbox-group input {    
    margin-right: 10px;    
}

.checkbox-group label {
    color: var(--Gray, #4C4C4C);    
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.checkbox-group .link_check {    
    text-decoration: none;
    color: var(--Violet-doux, #8D658A);    
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-decoration-line: underline;
}

.submit-btn {       
    border: none;  
    display: flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Disable-gradient, linear-gradient(90deg, #9D9D9C -27.35%, #E5E1DB 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: #FFFFFF;
}
.submit-btn.active {
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    cursor: pointer;
}

.submit-btn.active:hover {
    border-radius: 26px;
    background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}
.section_submit{
    width: 100%;
    display: flex;
    justify-content: center;    
}
.form-header-login{
    width: 100%;
    margin-bottom: 27px;        
}
.form-header-login p button{
    all:unset;
    cursor: pointer;
}

.form-header-login h2 {        
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;    
}

.section_submit_login{
    width: 100%;
    display: flex;
    justify-content: center;    
}
.footer_text{
    color: var(--Violet-doux, #8D658A);    
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;    
    line-height: normal;    
}
.footer_text .lien{
    color: var(--Violet-doux, #8D658A);                
    font-weight: 900 !important;
    line-height: normal;
    text-decoration: none;
}

.footer_text P button{    
    all:unset;
    cursor: pointer;    
}
.quote{
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #F6F6F6;
    border-radius: 26px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: relative;
    right: 40px;
}

.quote1{
    width: 183px;
    gap: 10;
}
.quote2{
    width: 140px;
    gap: 10;
    position: static;
}
.quote3{
    width: 222px;
    gap: 10;
    position: static;
}
.quote-child{
    color: #8D658A;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    word-wrap: break-word;
}

.img-left{
    width: 198.438px;
    height: 194px;
    flex-shrink: 0;
        background: url('../../public/assets/images/login/image_login.png') 50% / cover no-repeat;
}

.form-header h2, .titre-1{
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins' !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
}
.h2-button{
    display: inline-flex;
    padding: 10px 40px;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: var(--Violet-doux, #8D658A);
    color: var(--Blanc, #FFF);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 20px;
}

.carrousel-div{
    width: 738px;
    height: 297px;
    flex-shrink: 0;
    border-radius: 26px;
    background: rgba(246, 246, 246, 0.54);
    display: flex;
}

.carrousel-divs{
    display: flex;
    flex-direction: column;
    gap: 18px;
    justify-content: center;
    margin-left: 30px;
}

.element{
    display: inline-flex;
    padding: 5px 10px;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 10px;
    border-radius: 26px;
    background: var(--Blanc, #FFF);
    color: var(--Violet-doux, #8D658A);

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.carrousel-div-img1{
    width: 262px;
    height: 146px;
    flex-shrink: 0;
    /* border-radius: 8px; */
    background: url('../../public/assets/images/seoa/img2v3.png') center / contain no-repeat;
    position: absolute;
    right: 0;
    top: 116px;
}
.carrousel-div-img2{
    width: 214px;
    height: 95px;
    flex-shrink: 0;
    border-radius: 8px;
    background: url('../../public/assets/images/seoa/img2v2.png') -3.087px -7.201px / 101.895% 109% no-repeat;
    /* box-shadow: 0px 1px 24.9px -3px rgba(176, 139, 187, 0.17); */
    position: absolute;
}
.carrousel-div-img3{
    width: 166.402px;
    height: 262.243px;
    flex-shrink: 0;
    margin-left: auto;
    background: url('../../public/assets/images/seoa/img3v2.png') center / contain no-repeat;    
}
.carrousel-div-img4{
    width: 262px;
    height: 119px;
    flex-shrink: 0;
    /* border-radius: 8px; */
    background: url('../../public/assets/images/seoa/img4v3.png') center / contain no-repeat;
    position: absolute;
    right: 0;
    top: 124px;
}

.carrousel-div-img5{
    width: 140.052px;
    height: 212.044px;
    flex-shrink: 0;
    border-radius: 8px;
    background: url('../../public/assets/images/seoa/img5v2.png')  center / contain no-repeat;
    /* box-shadow: 0px 1px 24.9px -3px rgba(176, 139, 187, 0.17);   */
    position: absolute;
    
}
.carrousel-div-img6{
    width: 166px;
    height: 92px;
    transform: rotate(-6.625deg);
    flex-shrink: 0;
    border-radius: 8px;
    background: url('../../public/assets/images/seoa/img6v2.png') -3.087px -7.201px / 101.895% 109% no-repeat;
    /* box-shadow: 0px 1px 24.9px -3px rgba(176, 139, 187, 0.17);   */
    position: absolute;
    right: -2px;
}

.carrousel-div-img7{
    width: 262px;
    height: 38px;
    transform: rotate(9.339deg);
    flex-shrink: 0;
    border-radius: 8px;
    background: url('../../public/assets/images/seoa/img7v2.png') -3.087px -7.201px / 101.895% 109% no-repeat;
    /* box-shadow: 0px 1px 24.9px -3px rgba(176, 139, 187, 0.17);   */
    position: absolute;
    top: 22px;
    right: 5px;
}

.carrousel-div-img8 {
    width: 148.476px;
    height: 113.428px;
    flex-shrink: 0;
    /* border-radius: 8px; */
    background: url('../../public/assets/images/seoa/img8v3.png') center / contain no-repeat;
    position: absolute;
    left: 9px;
    z-index: 2; /* Ensures img8 is above img9 */
}

.carrousel-div-img9 {
    width: 212.565px;
    height: 163.603px;
    flex-shrink: 0;
    /* border-radius: 8px; */
    background: url('../../public/assets/images/seoa/img9v3.png') center / contain no-repeat;
    position: absolute;
    right: 10px;
    z-index: 1; /* Lower z-index, making it appear below img8 */
}



.button-link{
    color: inherit;
    text-decoration: unset;
}

.carrousel-next{
    display: flex;
    width: 100%;
    padding: 50px 57px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 26px;
    background: #FFF;
}
.carrousel-next-div{
    width: 141px;
    height: 14px;
    flex-shrink: 0;
}
rect{cursor: pointer;}
.error{
    align-self: stretch;
    color: #FF7E7E;

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.div-start{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    align-self: stretch;
}
.div-start-button{
    display: flex;
    width: 202px;
    height: 50px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: var(--Background-Blanc, #FFF);

    /* Elements/Button */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    cursor: pointer;
}
.button_web{
    display: flex !important;
    height: 40.05px !important;
    width: 137px !important;
    padding: 10px 30px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;        
    font-family: 'Poppins' !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.div-start-button:hover {
    border-radius: 26px;
    background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}
.relative{position: relative;}
.width100{width: 100% !important;}
.width50{width: 50%;}
.width45{width: 45%;}
.width30{width: 30%;}
.width62{width: 62%;}
.width33{width: 33%;}
.width70{width: 70%;}
/* .width70{width: 60%;} */
.width10{width: 10%;}
.width5{width: 5%;}
.width8{width: 8%;}
.width20{width: 20%;}
.width25{width: 25%;}
.width15{width: 15%;}
.ml50{margin-right: 50px;}

.loadMoreBoutton{
    width: 140px;
    height: 25px;
    padding: 0px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: var(--Background-Blanc, #FFF);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    border: initial;
    text-align: center;
    margin-top: 20px;
}
.nowrap{    white-space: nowrap;}

.table_row2 {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 8px;
    margin-bottom: 5px;
    width: 1300px;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Blanc, #FFF);
    height: 46px;
    color: var(--Gray, #4C4C4C);
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.table_header2 {
    display: flex;
    align-items: center;
    /* padding: 8px; */
    padding: 0px;
    margin-bottom: -7px;
    width: 1300px;
    flex-shrink: 0;
    height: 46px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    color: var(--Strong-gray, #9D9D9C);
}
.under_row .under_table_header2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    margin-bottom: -7px;
    width: 1290px;
    flex-shrink: 0;
    height: auto;
    padding-top: 8px;
    color: var(--Strong-gray, #9D9D9C);
}
.under_row .sous_row2{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.style_row2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-bottom: 5px;
    width: 1290px;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Background, rgba(176, 139, 187, 0.10)) !important;
    height: 46px;
    color: var(--Gray, #4C4C4C);
    border-bottom: 1px solid #ddd;
}

.svg_plus{
    margin-right: 5px;
    margin-left: 10px;
    margin-top: 2px;
    cursor: pointer;
}


.table-category{
    /* width: 8%; */
    width: 200px;
    margin-left: 2%;
    text-wrap: nowrap;
}
.table-nb-page{
    width: 12%;
    text-align: center;
}
.table-request{
    width: 19%;
    text-align: center;
}
.table-impressions{
    width: 6%;
    text-align: center;
    margin-left: 31px;
}
.ctr{
    margin-left: 26px;
}
.prix{
    margin-left: 35px;
}
.prixContent{
    margin-left: -9px;
}
.categorieSeoa{
    width: 134px;
    margin-left: 150px;
}
.nbrPage{
    margin-left: 118px;
}
.nbrRqt{
    margin-left: 53px;
}
.impr{
    margin-left: 58px;
}
.overflow-hidden{overflow: hidden;}

.option_svg{
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
}

.div_opiton{
    display: flex;
    width: 130px;
    height: 28.143px;
    padding: 3px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    border: 1px solid var(--Soft-gray, #F6F6F6);
    background: #fff !important;

    position: absolute;
    /* background: red; */
    top: 30px;
}
.div_opiton p{
    display: flex;
    height: 8.571px;
    padding: 0px 5px;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    align-self: stretch;

    color: var(--Strong-gray, #9D9D9C);
    /* Detail */
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    padding: 0;
    margin: 0;
}

.custom-select {
    position: relative;
    width: 200px;
  }
  
  .custom-select select {
    width: 100%;
    height: 35px;
  }
  
  .options {
    position: absolute;
    top: 25px;
    left: -100px;
    right: 0;
    border: 1px solid #ccc;
    background-color: white;
    max-height: 150px;
    overflow-y: auto;
    width: 200px;
    z-index: 10 !important;
    cursor: pointer;
  }
  .cursor-pointer {
    cursor: pointer;
}
  
  .custom-select:hover .options {
    display: block;
  }
  
  .options div {
    text-align: left;
    padding: 8px;
  }
  
  .options div:hover {
    background-color: #f0f0f0;
  }
  .selectOptionDiv{
    display: flex;
    height: 8.571px;
    padding: 0px 5px;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    
    color: var(--Pche-vive, #FF8366);

    /* Detail */
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .seoa-interval{
    display: flex;
    justify-content: space-between;                         
    font-family: 'Poppins';
    font-size: 12px;        
    line-height: 18px;
    text-align: left;              
    margin-left: 20px;

    width: 200px;
    height: 18px;
    gap: 20px;    
    color:#FF8366;
}

  .seoa-interval .seoa-interval-date{
    all:unset;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;    
}


.div22{
    /* width: 1210px;
    height: 33px; */
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Background--SEOA, #F2EEF3);
}

.div22 td{
    color: var(--Gray, #4C4C4C);

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* padding: 8px 18px; */
    padding: 2px 10px;
    word-wrap: break-word;
    white-space: nowrap;
}

.pagename{
    overflow: hidden;
    white-space: nowrap;
}
.tableListe{
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

/* .carousel-control-next,
.carousel-control-prev {          
    background: none;
    border: none; 
    opacity: 1;    
    text-decoration: none;  
    color: #04D9D9;               
} */
.span-category{
    margin-left: 10px;
    background: #FCAE89;
    padding: 5px 4px;
    border-radius: 40px;
}
.carousel-control-prev-icon:after {
    display: none;
    content: '<';
    font-size: 55px;
    color: #04D9D9;
  }

  .carousel-control-next-icon:after{
    display: none;
    content: '>';
    font-size: 55px;
    color: #04D9D9;
  }

.selectedSeao{
    background: #D9CDDC;
}
.carousel-control-next, .carousel-control-prev, .carousel-indicators{display: none;}

.number{
    color: #8D658A !important;
}

.disabled{ background: linear-gradient(90.07deg, rgb(157, 157, 156) -27.35%, rgb(229, 225, 219) 77.87%);}

.config-select{
    display: flex;
    width: 96px;
    padding: 3px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.zone-date-aff{width: 280px !important;}

.under_row .under_table_header .row_header {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;

}


/* pop up configuration campagne  */
.config-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
}


.config-popup-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 848px;
    padding: 30px 20px; /* Adjust padding for even spacing */
    border-radius: 26px;
    border: 1px solid var(--Soft-gray, #F6F6F6);
    background: #F7F7F7;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 141px;
    margin-top: 67px;
}

/* Close button styling */
.config-popup-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px; /* Adjusted for better alignment with padding */
    top: 20px; /* Matches popup's top padding for balance */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease; /* Optional animation */
}

.config-popup-close:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
}

.config-popup-title {
    color: var(--Pche-vive, #FF8366);
    font-family: Poppins, sans-serif; /* Added fallback font */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.config-popup-date {
    display: flex;
    width: 477px;
    padding: 30px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 26px;
    border: 4px solid var(--Soft-gray, #F6F6F6);
    background: var(--Blanc, #FFF);
}

.config-popup-dateDsip {
    display: flex;
    width: 477px;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    border-radius: 26px;
    border: 0px solid var(--Soft-gray, #F6F6F6);
    background: var(--Blanc, #FFF);
}



.config-popup-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.config-popup-duration {
    color: var(--Strong-gray, #9D9D9C);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.config-popup-box {
    display: flex;
    width: 183px;
    height: 40px;
    padding: 10px 30px;
    justify-content: space-between;
    align-items: center;
    border-radius: 26px;
    border: 1px solid var(--Active-gradient, #FF8366);
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}





.config-popup-duration:hover {
    background: #EDEDED;
}

/* Selected style */
.config-popup-duration.selected {
    display: inline-flex;
    height: 40px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    color: white;
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
   
   
}

.sixmois.selected {
    margin-left: -30px;
}

.douzemois.selected {
    margin-right: -30px;
}

.left {
    flex: 1;
}

.right {
    flex: 1;
}

.config-popup-option {
    color: var(--Pche-vive, #FF8366);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-container {
    display: flex;
    justify-content: space-between; /* Distribute buttons to far left and right */
    width: 100%; /* Ensures the container takes full width */
}

.custom-buttonRetour {
    display: flex;
    height: 40.05px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    border: 2px solid var(--Active-gradient, #FF8366);
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    background-color: transparent;
    color: var(--Active-gradient, #FF8366);
    cursor: pointer;
}

.validate-button {
    display: flex;
    height: 40.05px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: white;
    cursor: pointer;
    border: none;
}
.validate-button-search {
    display: flex;
    height: 40px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: white;
    cursor: pointer;
    border: none;
    font-family: 'Poppins', sans-serif; /* Ensure Poppins is imported */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 50px;
}


.config-popup-container-date {
    display: flex;
    justify-content: space-between; /* Align text to left and box to right */
    align-items: center; /* Vertically center the items */
    width: 100%; /* Ensure it takes the full width */
}


.config-popup-box-date {
    display: flex;
    width: 183px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 26px;
    background: #F7F7F7;
}

.date-input {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    color: var(--Strong-gray, #9D9D9C);
    text-align: center;
    padding: 5px;
}



.config-popup-container-exclu{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.exclusivity-select {
    display: flex;
    padding: 10px 20px;
    
    align-items: center;
    gap: 20px;
    border-radius: 26px;
    background: var(--Soft-gray, #F6F6F6);
}

.exclusivity-checkbox {
    width: 12px;
    height: 20px;
    cursor: pointer;
}

.exclusivity-text {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: var(--Strong-gray, #9D9D9C);
}

.config-popup-p {
    color: var(--Strong-gray, #9D9D9C);
    font-family: Poppins, sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    justify-content: left;
    line-height: 150%;
}

.expandable_row {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--Soft-gray, #F6F6F6);
    width: 82%;
    /* margin-bottom: 30px; */
    margin-bottom: 2px;
    margin-left: 20px;
    color: var(--Gray, #4C4C4C);
  }
  


  .expandable_cell,
  .expandable_cell_google,
  .expandable_cell_position,
  .expandable_cell_imp,
  .expandable_cell_ctr,
  .expandable_cell_icon {
      flex: 1; 
      max-width: 200px; 
      text-align: center; 
      padding: 5px;
      overflow: hidden; 
      white-space: nowrap; 
      text-overflow: ellipsis; 
      box-sizing: border-box; 
  }
  
  /* Optional: Add a tooltip to show full text on hover */
  .expandable_cell:hover,
  .expandable_cell_google:hover,
  .expandable_cell_position:hover,
  .expandable_cell_imp:hover,
  .expandable_cell_icon:hover,
  .expandable_cell_ctr:hover {
      overflow: visible; 
      white-space: normal; 
      position: relative;
      z-index: 1;
  }



  .orange-color{color:#FF8366}

  .price-container {
    display: flex;
    /* align-items: center; */
    gap: 10px; /* Space between the text and the box */
  }
  
  .config-text {
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins', sans-serif; /* Ensure Poppins is imported */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 9px;
  }
  
  .price-display {
    display: flex;
    width: 138px;
    height: 40px;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 26px;
    border: 4px solid var(--Soft-gray, #F6F6F6);
    background: var(--Blanc, #FFF);
  }
  
  .price-value {
    color: var(--Pche-vive, #FF8366);
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  


.parent_expandable_row{
    padding-top: 6px;
    padding-left: 10px;
}
.expandable_row_1{
    width: 38%  !important;
    float: left;
}
.expandable_row_2{
    width: 36%  !important;
    float: left;
}
.expandable_row_3{
    width: 90px  !important;
    float: left;
}
.expandable_row_4{
    width: 115px  !important;
    float: left;
}
.expandable_row_5{
    width: 80px  !important;
    float: left;
}
