.custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure the modal is on top */
}

.custom-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1128px;
    /* Modal width */
    padding: 0px 10px;
    /* Padding around the modal */
    border-radius: 26px;
    border: 1px solid var(--Soft-gray, #F6F6F6);
    /* Border color */
    background: #FFF;
    /* Background color */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Optional shadow */
}

.modal-header {
    display: flex;
    align-items: center;
    /* Vertically center the items */
    justify-content: space-between;
    /* Space between button and title */
    width: 100%;
    /* Full width of the modal */
    padding: 10px;
}

.modal-title {
    display: flex;
    /* Use flex to align title and subtitle */
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    flex-grow: 1;
    /* Take available space to center the title */
    gap: 10px;
}

.title-highlight {
    color: var(--Pche-vive, #FF8366);
    /* Color for "Gestion de votre campagne" */
    font-family: 'Poppins', sans-serif;
    /* Ensure 'Poppins' is loaded */
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
}

.title-subtitle {
    display: flex;
    /* Ensure the subtitle is displayed inline */
    padding: 10px 40px;
    /* Add padding around the subtitle */
    align-items: center;
    /* Center vertically */
    gap: 10px;
    /* Space between title and subtitle */
    border-radius: 50px;
    /* Rounded corners */
    background: var(--Violet-doux, #8D658A);
    /* Background color for subtitle */
    color: var(--Blanc, #FFF);
    /* Text color for subtitle */
    font-family: 'Poppins', sans-serif;
    /* Ensure 'Poppins' is loaded */
    font-size: 24px;
    /* Larger font size for subtitle */
    font-weight: 900;
    /* Bold font weight for subtitle */
    line-height: normal;
    /* Normal line height */
}

.close-button {
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.close-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    /* Allow the container to grow and take up space */
}

/* Additional styles for the modal body and footer can remain unchanged */
/* .modal-body {
    margin-top: 20px; 
    width: 100%; 
} */
.modal-body {
    display: flex;
    width: 1048px;
    /* Adjust the width as needed */
    padding: 10px 0;
    /* Vertical padding for the body */
    flex-direction: column;
    /* Stack elements vertically */
    justify-content: center;
    /* Center the content vertically */
    align-items: center;
    /* Center the content horizontally */
    gap: 10px;
    /* Space between elements */
    border-radius: 26px;
    /* Rounded corners */
    border: 1px solid var(--Violet-doux, #8D658A);
    /* Border with specified color */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Optional: Add a shadow for depth */
    background-color: white;
    /* Optional: Set background color */
}

.footer-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #8D658A;
    /* Your button color */
    color: #FFF;
    /* Text color */
    cursor: pointer;
}

.footer-button:hover {
    background-color: #7B5C8A;
    /* Hover effect */
}

.seo-title {
    color: var(--Gray, #4C4C4C);
    /* Color for title */
    font-family: 'Poppins', sans-serif;
    /* Ensure 'Poppins' is loaded */
    font-size: 20px;
    /* Font size */
    font-style: normal;
    /* Normal font style */
    font-weight: 700;
    /* Bold font weight */
    line-height: normal;
    /* Normal line height */
}

.highlight {
    color: var(--Pche-vive, #FF8366);
    /* Color for highlighted text */
    font-family: 'Poppins', sans-serif;
    /* Ensure 'Poppins' is loaded */
    font-size: 20px;
    /* Same font size */
    font-style: normal;
    /* Normal font style */
    font-weight: 700;
    /* Bold font weight */
    line-height: normal;
    /* Normal line height */
}


.modal-description {
    color: var(--Gray, #4C4C4C);
    /* Text color */
    font-family: 'Poppins', sans-serif;
    /* Ensure 'Poppins' is loaded */
    font-size: 12px;
    /* Font size */
    font-style: normal;
    /* Normal font style */
    font-weight: 600;
    /* Semi-bold font weight */
    line-height: normal;
    /* Normal line height */
}

.span-description {
    color: var(--Pche-vive, #FF8366);


    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.content-table {
    width: 100%;
    /* Ensure table takes full width */
    table-layout: fixed;
    /* Makes sure that cells have fixed widths */
}

.content-cell {
    padding: 20px;
    /* Adjust padding as needed */
    width: 33%;
    /* Ensure each cell takes an equal width */
    vertical-align: top;
}

.modal-footer {
    padding: 10px;
    /* Adjust padding as needed */
    display: flex;
    /* Use flexbox to align items */
    justify-content: flex-start;
    /* Align items to the left */
    align-items: center;
    /* Center items vertically */
}

.footer-text {
    color: var(--Gray, #4C4C4C);
    /* Text color for the question */
    font-family: 'Poppins', sans-serif;
    /* Font family */
    font-size: 12px;
    /* Font size */
    font-weight: 900;
    /* Font weight */
    line-height: normal;
    /* Line height */
}

.footer-email {
    color: var(--Lavande-clair, #B08BBB);
    /* Text color for the email */
    font-family: 'Poppins', sans-serif;
    /* Font family */
    font-size: 12px;
    /* Font size */
    font-weight: 900;
    /* Font weight */
    line-height: normal;
    /* Line height */
    margin-left: 5px;
    /* Add some space between the text and the email */
}

.image-container {
    position: relative;
    width: 144px;
    height: 216px;
    flex-shrink: 0;
    border-radius: 8px;
    background: url('/public/assets/images/info/info.png') transparent 50% / cover no-repeat;
    box-shadow: 1px 1px 17.6px 10px rgba(157, 157, 156, 0.10);
    margin-top: 16px;
}

.background-overlay {
    position: absolute;
    /* Position it over the image */
    top: 0;
    /* Align to top */
    left: 0;
    /* Align to left */
    right: 0;
    /* Align to right */
    bottom: 0;
    /* Align to bottom */
    width: 173px;
    height: 68px;
    transform: translate(-7%, 145%);
    background: rgba(176, 139, 187, 0.20);
    /* Background color */
    border-radius: 8px;
    /* Match the container's border radius */
    z-index: 1;
    /* Ensure it's above the image */
    display: flex;
    /* If you want to center content */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}


.image-container-affiliation {
    width: 231px;
    height: 353px;
    flex-shrink: 0;
    background: url('/public/assets/images/info/info_affiliation.png') lightgray 50% / cover no-repeat;
    box-shadow: 1px 1px 17px 10px rgba(0, 0, 0, 0.10);
    position: relative;
    /* Needed for the overlay to be positioned correctly */
}

.background-overlay-affiliation {
    width: 284.244px;
    height: 100.246px;
    transform: rotate(-6.211deg);
    flex-shrink: 0;
    border-radius: 8px;
    background: rgba(176, 139, 187, 0.20);
    position: absolute;
    /* Makes sure the overlay is positioned inside the container */
    top: 70%;
    /* Adjust the position of the overlay relative to the container */
    left: 0;
}

/* -------------------------------- */

.image-row {
    display: flex;
    justify-content: space-between;
    gap: 7px;
    /* Adjust the gap between the image blocks */
    /* margin-top: 20px; */
}

.image-block {
    text-align: center;
    /* Centers the title and image */
}

.image-title {
    color: var(--Pche-vive, #FF8366);
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    /* Adds space between the title and image */
}

/* Style for each image */
.image-covering,
.image-banner,
.image-bloc {
    width: 161px;
    height: 123px;
    flex-shrink: 0;
    background: lightgray 50% / cover no-repeat;
    border-radius: 8px;
    /* Optional: Rounds the image corners */
}

.image-covering .background-overlay {
width: 170px;
height: 36.542px;
transform: translate(317%, 714%);
}



.image-banner .background-overlay {
width: 170px;
height: 35px;
transform: translate(416%, 859%);

}

.image-bloc .background-overlay {
width: 68px;
height: 67px;
transform: translate(1431%, 493%);
}

/* Specific background images for each block */
.image-covering {
    background: url('/public/assets/images/info/display1.png') lightgray 50% / cover no-repeat;
}

.image-banner {
    background: url('/public/assets/images/info/display2.png') lightgray 50% / cover no-repeat;
}

.image-bloc {
    background: url('/public/assets/images/info/display3.png') lightgray 50% / cover no-repeat;
}


