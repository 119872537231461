.popup-content {
    position: absolute;
    top: 100px; /* Adjust based on where you want the pop-up to appear */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 539px;
    padding: 50px 10px;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    border-radius: 26px;
    background-color: #FFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(107, 103, 103, 0.25);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99; /* Ensure it's above other elements */
  }
  
  .icon-container {
    margin: 20px 0; /* Adjust margin as needed */
  }
  
  .icon-container svg {
    width: 24px;
    height: 24px;
  }
  
  
  .exclusivity-title {
    color: var(--Pche-vive, #FF8366);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .modal-text {
    color: var(--Gray, #4C4C4C);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 497px;
  }
  
  .modal-button {
    color: var(--Background-Blanc, #FFF);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: flex;
    width: 202px;
    height: 50px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    border: none;
  }