.secondStyle {
    display: flex;
    width: 100%; /* Make it responsive */
    height: 80vh; /* Full viewport height */
    padding: 20px; /* Add some padding */
    flex-direction: column;
    justify-content: space-between; /* Space between content */
    align-items: center;
    box-sizing: border-box;
    overflow: visible;
}

.message-content {
    color: var(--Gray, #4C4C4C);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5; /* Add spacing for better readability */
    margin: auto auto;
}

.message-content .bold-text {
    color: var(--Gray, #4C4C4C);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
}

/* Button group fixed at the bottom */
.button-group {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
}

.accueil-btn {
    display: flex;
    height: auto; /* Let the height be determined by the content */
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 26px;
    background: var(--Soft-gray, #F6F6F6); /* Light gray background */
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: var(--Pche-vive, #FF8366); /* Text color */
    font-family: 'Poppins', sans-serif; /* Font family */
    font-size: 14px; /* Font size */
    font-style: normal;
    font-weight: 500; /* Font weight */
    line-height: normal; /* Line height */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap; /* Prevent text wrapping */
}
.continue-btn {
    display: flex;
    height: auto; /* Let the height be determined by the content */
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: var(--Background-Blanc, #FFF);
    font-family: 'Poppins', sans-serif; /* Font family */
    font-size: 14px; /* Font size */
    font-style: normal;
    font-weight: 500; /* Font weight */
    line-height: normal; /* Line height */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap; /* Prevent text wrapping */
}


.accueil-btn:hover {
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
}
.continue-btn:hover {
    background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
}

