

.zone-date-aff{
    display: flex;    
    height: 28px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 26px;
    background: #F7F7F7;
    gap: 10px;    
    /* margin-bottom: 40px; */
    height: 28px;
    /* margin-top: 10px;   */        
    flex-shrink: 0;   
    margin-left: 50px;
    cursor: pointer;
}
.zone-date-svg-aff{
    width: 12px;
    height: 12px;
    flex-shrink: 0;
}
.zone-date-svg-aff svg{
    margin-bottom: 10px;
}
.zone-date-input-aff{
    width: 96px;
    flex-shrink: 0;
    color: var(--Strong-gray, #9D9D9C);

    /* Text/Normal */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.zone-date-button-aff{
    display: flex;
    width: 26px;
    height: 20px;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
}

.zone-date-button-aff svg{
    width: 12px;
    height: 12px;
    flex-shrink: 0;
}

.zone-date-aff .remove-button{
    color : #FF8366;
}

.aff-interval{
    display: flex;
    justify-content: space-between;                         
    font-family: 'Poppins';
    font-size: 12px;        
    line-height: 18px;
    text-align: left;              
    margin-left: 20px;

    width: 200px;
    height: 18px;
    gap: 20px;    
    color:#FF8366;
}

.aff-interval .aff-interval-date{
    all:unset;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;    
}

.modal-save-aff{
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
    align-self: stretch;
}
.modal-save-aff button{
    display: flex;
    width: 202px;
    height: 50px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;    
    box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
    color: var(--Background-Blanc, #FFF);

    /* Elements/Button */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: initial;
}

.availability-bar {
  display: flex;
  width: 836px; /* Keep the width */
  height: 32px; /* Keep the height */
  padding: 6px 18px; /* Adjust padding */
  margin-top: -5px;
  justify-content: space-between; /* Keep justify content */
  align-items: center; /* Keep align items */
  flex-shrink: 0; /* Add flex-shrink */
  border-radius: 0px 0px 26px 26px; /* Update border-radius */
  border-top: 1px solid var(--Background---AFFILIATION, #F3F0EE); /* Change border */
  background: var(--Blanc, #FFF); /* Keep the background color */
  margin-bottom: 7px; /* Keep margin-bottom */
  font-family: 'Poppins'; /* Keep font family */
  font-size: 12px; /* Keep font size */
  font-weight: 600; /* Keep font weight */
}

.availability-bar .s_colum {
  white-space: nowrap; /* Keep white-space rule */
}
  
  .calendar-section {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
 
  
  .calendar-icon {    
    color: #8D658A;
    margin-right: 10px;    
  }
  
  .availability-link {    
    text-decoration: underline;      
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #8D658A;
  }
  
  .option {
    display: flex;
    align-items: center;
    color: #8D658A;
    margin-right: 20px;

  }

  .cursor-pointer {
    cursor: pointer;
}
  
  .option input {
    margin-left: 5px;
    margin-right: 10px;
  }
  
  .info-icon {
    font-size: 14px;
    color: #8D658A;
    margin-left: 5px;
  }
  .info-icon{
    cursor: pointer;
}
  
  .prix-aff {
    color: #8D658A;        
    margin-right: 20px;    
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;

  }
  
  .cart-button button {
    background-color: #F28264; /* Orange button */
    border: none;
    border-radius: 50%;
    color: white;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .cart-button button:hover {
    background-color: #e27157; /* Darker on hover */
  }

.shoppign-aff{
  display: flex;
  justify-content: center;
}

.icon_shoppign-aff svg{
  color: #FFFFFF;
}
.icon_shoppign-aff{
  color: #FFFFFF;
  display: flex;
  width: 42px;
  height: 20px;
  /* padding: 10px 30px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 26px;
  background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
  box-shadow: 0px 4px 7.7px 0px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.icon_shoppign-aff:hover {
  background: linear-gradient(90deg, #FCAE89 -12.46%, #FF8366 89.1%);
}



.icon_delete svg{
  color: #FF8366;
}
.icon_delete{
  color: #FFFFFF;
  display: flex;
  width: 42px;
  height: 20px;
  /* padding: 10px 30px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 26px;
  background: transparent;
  border: 1px solid #FF8366;
  cursor: pointer;
}



.selectedAffiliation{
  background: #E3D3C8 !important;
}

/* .selectedCategorie{
  background: #ff8366 !important;
  color: #fff !important;
} */


/* modal */


.config-popup-box-affiliation {
  display: flex;
  height: 40px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 26px;
  background: var(--Disable-gradient, linear-gradient(90deg, #9D9D9C -27.35%, #E5E1DB 77.87%));
  box-shadow: 0px 4px 7.7px rgba(0, 0, 0, 0.09);
}

.config-popup-duration-affiliation {
  color: var(--Background-Blanc, #FFF);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
}


.config-popup-box-affiliation.selected {
  display: flex;
  height: 40px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 26px;
  background: var(--Active-gradient, linear-gradient(90deg, #FF8366 -27.35%, #FCAE89 77.87%));
  box-shadow: 0px 4px 7.7px rgba(0, 0, 0, 0.09);
  color: white; /* Optional: set text color to white when selected */
}

.nbrPageAff{
  margin-left: 44px;
}
.nbrRqtAff{
  margin-left: 44px;
}
.ctrAff{
  margin-left: 27px;
}
.impressionAff{
  margin-left: 45px;
}
.prixAff{
  margin-left: 80px;
}
.categorieAff{
  width: 255px;
    margin-left: 66px;
}


